@if (!isInHome.includes('/cart')) {
  <div [ngClass]="currentUrl?.includes('/cart') ? 'hidden' : 'sticky-nav'">
    <ul class="navbar-nav ms-auto card card-vertical-nav">
      <li
        id="first"
        #firstLi
        class="nav-item dropdown pointer pharmacies"
        routerLink="/home"
        [ngClass]="{ active: isPharmaciesRouteActive }"
      >
        <a class="nav-link">Pharmacies</a>
      </li>
      <li routerLinkActive="active" (click)="navigateToCart()" class="nav-item dropdown pointer panier">
        <a class="nav-link">Mon panier</a>
      </li>
      <li routerLink="/orders" class="nav-item dropdown pointer orders" [ngClass]="{ active: isOrdersRouteActive }">
        <a class="nav-link">Mes commandes</a>
      </li>
      <li routerLink="/favorites" routerLinkActive="active" class="nav-item dropdown pointer favoris">
        <a class="nav-link">Mes favoris</a>
      </li>
      <li routerLink="/payments" id="last" routerLinkActive="active" class="nav-item dropdown pointer payments">
        <a class="nav-link">Mes paiements</a>
      </li>
      <li (click)="setActive('profile')" [ngClass]="{ active: activeItem === 'profile' }" class="nav-item dropdown pointer profile d-none">
        <a class="nav-link">Mon profil</a>
      </li>
    </ul>
    <div class="row mt-3 mb-5">
      <div class="pharmacy-info-box" *ngIf="disable">
        <div class="pharmacy-info">
          <ul class="d-flex flex-column">
            <li class="nav-item">
              <img src="/content/images/icons/medicine.svg" alt="" />
              {{ pharmacieDetails?.commercialName ? pharmacieDetails?.commercialName : 'Pharmacie invalid' }}
            </li>
            <li class="nav-item">
              <img src="/content/images/icons/marker.svg" alt="" />
              {{ pharmacieDetails?.address ? pharmacieDetails?.address : 'Adresse invalid' }}
            </li>
            <li class="nav-item">
              <img src="/content/images/icons/clock.svg" alt="" />
              {{
                pharmacieDetails?.schedule
                  ? formatHour(pharmacieDetails?.schedule.openingHour, pharmacieDetails?.schedule.closingHour)
                  : 'Horraire non disponible'
              }}
            </li>
            <li class="nav-item">
              <img src="/content/images/icons/phone-call.svg" alt="" />
              +33 {{ pharmacieDetails?.phoneNumber ? pharmacieDetails?.phoneNumber : 'Téléphone invalid' }}
            </li>
            <li class="nav-item">
              <img src="/content/images/icons/envelope.svg" alt="" />
              {{
                pharmacieDetails?.creatorUser?.internalUser?.login ? pharmacieDetails?.creatorUser?.internalUser?.login : 'Email invalid'
              }}
            </li>
          </ul>
        </div>
      </div>
      <div class="sidebar-ads mb-3" style="visibility: hidden">
        <img src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/cc1273c0-b816-415d-cc48-345cf9ff9400/Cpub" alt="" />
      </div>
    </div>
  </div>
}
<ng-template #modalCartVide let-modalCartVide>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalCartVide.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/problem.svg" alt="" />
      <p class="swalModal">Votre panier est vide</p>
      <span class="mb-4 productExist"> Il semble que vous n'avez aucun article dans votre panier. Vous pouvez ajouter des produits. </span>
      <div class="d-flex justify-content-between w-100">
        <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="modalCartVide.close('closeCart')">OK</button>
      </div>
    </div>
  </div>
</ng-template>
