import { Component, OnInit } from '@angular/core';
import { Router, Navigation } from '@angular/router';
import { RegisterService } from 'app/account/register/register.service';
import { AccountService } from 'app/core/auth/account.service';
import { CommonModule } from '@angular/common';
import { LoginService } from 'app/login/login.service';

@Component({
  selector: 'jhi-pharmacy-strip-conneced',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pharmacy-strip-conneced.component.html',
  styleUrl: './pharmacy-strip-conneced.component.scss',
})
export class PharmacyStripConnecedComponent implements OnInit {
  user_id: any;
  accountClient: any = {};

  constructor(
    private registerService: RegisterService,
    private accountService: AccountService,
    private loginService: LoginService,
  ) {}
  isLoading = false;
  ngOnInit() {
    this.loadProfile();
  }
  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.accountClient = res;
    });
  }
  stripeAccount(): void {
    this.isLoading = true; // Start the loader
    this.registerService.createStripeAccount(this.accountClient.id).subscribe({
      next: res => {
        console.log('Stripe account created:', res);
        this.accountService.getAuthenticatedUser().subscribe({
          next: updatedProfile => {
            this.accountClient = updatedProfile;
            if (this.accountClient.applicationUser?.onBoardingUrl) {
              window.location.href = this.accountClient.applicationUser.onBoardingUrl;
              this.loginService.logout();
            } else {
              console.error('onBoardingUrl is still null after creating Stripe account');
            }
            this.isLoading = false; // Stop the loader
          },
          error: err => {
            console.error('Failed to reload profile after creating Stripe account:', err);
            this.isLoading = false; // Stop the loader
          },
        });
      },
      error: err => {
        console.error('Failed to create Stripe account:', err);
        this.isLoading = false; // Stop the loader
      },
    });
  }
}
