<div class="row mt-5">
  <h2 class="lead" style="color: #141414">Liste des Pharmacies</h2>
</div>
<div class="row">
  <div class="col-9">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1" (click)="getInvitation()">
        <a ngbNavLink>Invité</a>
        <ng-template ngbNavContent>
          <div [ngClass]="createdWithPrescriptionPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                    <th scope="col">Statut</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let invitation of invitations; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ invitation.pharmacyOrderId ? invitation.pharmacyOrderId : '---' }}</td>
                      <td>{{ invitation.pharmacyCommercialName ? invitation.pharmacyCommercialName : '---' }}</td>
                      <td>{{ invitation.pharmacyAddress ? invitation.pharmacyAddress : '---' }}</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="openModalDetails(invitation)"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer" (click)="sendInvitation(invitation.id)" [disabled]="invitation?.status === 'ACCEPTED'">
                            Envoi Invitation
                          </button>
                        </div>
                      </td>
                      <td>
                        <button class="attenteLivreur" *ngIf="invitation?.status === 'CREATED'">Nouveau</button>
                        <button class="enRoute" *ngIf="invitation?.status === 'PENDING'">En cours</button>
                        <button class="attenteLivreur" *ngIf="invitation?.status === 'ACCEPTED'">Accepté</button>
                        <button class="clientAbsent" *ngIf="invitation?.status === 'EXPIRED'">Expiré</button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--
            -->
            <div class="card flex justify-content-center" *ngIf="invitations?.length">
              <p-paginator (onPageChange)="onPageChangeInvitation($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
            <ng-container *ngIf="!invitations?.length">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink (click)="changeStatus('activated')">Actif</a>
        <ng-template ngbNavContent>
          <div [ngClass]="inProgressPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let pharma of pharmacy; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ pharma.pharmacyOrderId ? pharma.pharmacyOrderId : '---' }}</td>
                      <td>{{ pharma.commercialName ? pharma.commercialName : '---' }}</td>
                      <td>{{ pharma.address ? pharma.address : '---' }}</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="openModalDetailsActif(pharma)"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <label class="switch">
                            <input
                              type="checkbox"
                              [checked]="pharma.visibility"
                              (change)="pharmacyVisibility(pharma.id, pharma.visibility)"
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" *ngIf="pharmacy?.length">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
            <ng-container *ngIf="!pharmacy?.length">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink (click)="changeStatus('verified')">Vérifié</a>
        <ng-template ngbNavContent>
          <div [ngClass]="inProgressPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let pharma of pharmacy; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ pharma.pharmacyOrderId ? pharma.pharmacyOrderId : '---' }}</td>
                      <td>{{ pharma.commercialName ? pharma.commercialName : '---' }}</td>
                      <td>{{ pharma.address ? pharma.address : '---' }}</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="openModalDetailsActif(pharma)"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer">Vérifier</button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" *ngIf="pharmacy?.length">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
            <ng-container *ngIf="!pharmacy?.length">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink (click)="changeStatus('created')">En attente</a>
        <ng-template ngbNavContent>
          <div [ngClass]="paymentInstancePurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let pharma of pharmacy; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ pharma.pharmacyOrderId ? pharma.pharmacyOrderId : '---' }}</td>
                      <td>{{ pharma.commercialName ? pharma.commercialName : '---' }}</td>
                      <td>{{ pharma.address ? pharma.address : '---' }}</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="openModalDetailsActif(pharma)"></button>
                        </div>
                      </td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer" (click)="verified(pharma.creatorUser.internalUser.id)">Confirmer vérification</button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" *ngIf="pharmacy?.length">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
            <ng-container *ngIf="!pharmacy?.length">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink (click)="changeStatus('suspended')">Archivé</a>
        <ng-template ngbNavContent>
          <div [ngClass]="packagingPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let pharma of pharmacy; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ pharma.pharmacyOrderId ? pharma.pharmacyOrderId : '---' }}</td>
                      <td>{{ pharma.commercialName ? pharma.commercialName : '---' }}</td>
                      <td>{{ pharma.address ? pharma.address : '---' }}</td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="openModalDetailsActif(pharma)"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer">Réactiver</button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" *ngIf="pharmacy?.length">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
            <ng-container *ngIf="!pharmacy?.length">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div class="col-3">
    <div class="btn-wrap">
      <button class="btn-invite" (click)="openModal()">+ Inviter Pharmacien</button>
    </div>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<ng-template #modalgeo let-modalgeo>
  <form [formGroup]="invitationForme">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-center"></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="closeModal(modalgeo)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="form-container">
        <h4>Créer une pharmacie par invitation</h4>

        <!-- Section Gérant -->
        <p>Gérant</p>
        <div class="form-section">
          <div class="form-group">
            <input type="text" formControlName="firstName" placeholder="Prénom" class="form-control" />
          </div>
          <div class="form-group">
            <input type="text" formControlName="lastName" placeholder="Nom" class="form-control" />
          </div>
        </div>

        <div class="form-section">
          <div class="form-group">
            <input type="email" formControlName="email" placeholder="Email" class="form-control" />
          </div>
          <div class="form-group">
            <input type="text" formControlName="phone" placeholder="Num Tél" class="form-control" />
          </div>
        </div>

        <!-- Section Pharmacie -->
        <p>Pharmacie</p>
        <input
          type="text"
          id="denomination"
          placeholder="Dénomination Commerciale"
          class="form-control full-width mb-3"
          formControlName="pharmacyCommercialName"
        />
        <auto-complete
          (placeSelected)="onPlaceSelected($event)"
          style="display: block; margin-bottom: 8px; border: 1px solid #83b011; border-radius: 7px"
          [optionalInput]="pharmacyAddress"
          formControlName="pharmacyAddress"
        ></auto-complete>
        <div class="form-section">
          <div class="form-group">
            <input
              type="email"
              id="emailService"
              placeholder="Email de service"
              class="form-control"
              formControlName="pharmacyResponsibleEmail"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              id="numTelService"
              placeholder="Num Tél Service"
              class="form-control"
              formControlName="pharmacyPhoneNumber"
            />
          </div>
        </div>

        <div class="form-section">
          <div class="form-group">
            <input type="text" id="cip" placeholder="CIP" class="form-control" formControlName="pharmacyOrderId" />
          </div>
          <div class="form-group">
            <input type="text" id="siret" placeholder="Siret" class="form-control" formControlName="pharmacySiret" />
          </div>
        </div>

        <button class="btn btn-submit" [disabled]="invitationForme.invalid" (click)="createInvitation(); modalgeo.dismiss('Cross click')">
          Valider
        </button>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #modalDetails let-modalDetails>
  <form [formGroup]="invitationForme">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-center"></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="closeModal(modalDetails)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="form-container">
        <h4>Créer une pharmacie par invitation</h4>

        <!-- Section Gérant -->
        <p>Gérant</p>
        <div class="form-section">
          <div class="form-group">
            <input type="text" formControlName="firstName" placeholder="Prénom" class="form-control custom-placeholder" />
          </div>
          <div class="form-group">
            <input type="text" formControlName="lastName" placeholder="Nom" class="form-control custom-placeholder" />
          </div>
        </div>

        <div class="form-section">
          <div class="form-group">
            <input type="email" formControlName="email" placeholder="Email" class="form-control custom-placeholder" />
          </div>
          <div class="form-group">
            <input type="text" formControlName="phone" placeholder="Num Tél" class="form-control custom-placeholder" />
          </div>
        </div>

        <!-- Section Pharmacie -->
        <p>Pharmacie</p>
        <input
          type="text"
          id="denomination"
          placeholder="Dénomination Commerciale"
          class="form-control full-width mb-3 custom-placeholder"
          formControlName="pharmacyCommercialName"
        />
        <input
          type="text"
          id="adresse"
          placeholder="Adresse Pharmacie"
          class="form-control full-width mb-3 custom-placeholder"
          formControlName="pharmacyAddress"
        />

        <div class="form-section">
          <div class="form-group">
            <input
              type="email"
              id="emailService"
              placeholder="Email de service"
              class="form-control custom-placeholder"
              formControlName="pharmacyResponsibleEmail"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              id="numTelService"
              placeholder="Num Tél Service"
              class="form-control custom-placeholder"
              formControlName="pharmacyPhoneNumber"
            />
          </div>
        </div>

        <div class="form-section">
          <div class="form-group">
            <input type="text" id="cip" placeholder="CIP" class="form-control custom-placeholder" formControlName="pharmacyOrderId" />
          </div>
          <div class="form-group">
            <input type="text" id="siret" placeholder="Siret" class="form-control custom-placeholder" formControlName="pharmacySiret" />
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #modalDetailsActif let-modalDetailsActif>
  <form [formGroup]="invitationForme">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-center"></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="closeModal(modalDetailsActif)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="form-container">
        <h4>Créer une pharmacie par invitation</h4>

        <!-- Section Gérant -->
        <p>Gérant</p>
        <div class="form-section">
          <div class="form-group">
            <input
              type="text"
              [value]="pharmaDetails.creatorUser.internalUser.firstName"
              placeholder="Prénom"
              class="form-control custom-placeholder"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              [value]="pharmaDetails.creatorUser.internalUser.lastName"
              placeholder="Nom"
              class="form-control custom-placeholder"
            />
          </div>
        </div>

        <div class="form-section">
          <div class="form-group">
            <input
              type="email"
              [value]="pharmaDetails.creatorUser.internalUser.login"
              placeholder="Email"
              class="form-control custom-placeholder"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              [value]="pharmaDetails.creatorUser.phoneNumber"
              placeholder="Num Tél"
              class="form-control custom-placeholder"
            />
          </div>
        </div>

        <!-- Section Pharmacie -->
        <p>Pharmacie..</p>
        <input
          type="text"
          id="denomination"
          placeholder="Dénomination Commerciale"
          class="form-control full-width mb-3 custom-placeholder"
          [value]="pharmaDetails.commercialName"
        />
        <input
          type="text"
          id="adresse"
          placeholder="Adresse Pharmacie"
          class="form-control full-width mb-3 custom-placeholder"
          [value]="pharmaDetails.address"
        />

        <div class="form-section">
          <div class="form-group">
            <input
              type="email"
              id="emailService"
              placeholder="Email de service"
              class="form-control custom-placeholder"
              [value]="pharmaDetails.responsibleEmail"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              id="numTelService"
              placeholder="Num Tél Service"
              class="form-control custom-placeholder"
              [value]="pharmaDetails.phoneNumber"
            />
          </div>
        </div>

        <div class="form-section">
          <div class="form-group">
            <input type="text" id="cip" placeholder="CIP" class="form-control custom-placeholder" [value]="pharmaDetails.pharmacyOrderId" />
          </div>
          <div class="form-group">
            <input type="text" id="siret" placeholder="Siret" class="form-control custom-placeholder" [value]="pharmaDetails.siret" />
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
