<div class="row mt-5 mb-4">
  <div class="left-section">
    <div class="col-sm-12 pharmacy-stock">
      <h2 class="lead" style="color: #141414">Gestion de stock</h2>
      <span style="margin: 0rem 2rem"
        >Pour mettre à jour la liste des produits à vendre sur votre espace, il suffit de télécharger le fichier de stock sur cette
        interface.</span
      >
    </div>
    <div class="header-button">
      <button class="btn btn-primary btn-bill mr-md-0 m-md-2" (click)="openModal(modalUpload)">Importer</button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <h2 class="stockName">Liste des produits</h2>
  </div>
  <div class="product-search m-2 col">
    <input
      type="text"
      class="form-control category-input"
      id="text"
      name="search"
      (keyup)="applyFilter($event)"
      placeholder="Recherche ..."
      data-cy="text"
    />
    <span class="search-icon" style="margin: -2rem 2rem"></span>
  </div>
</div>
<div class="scorllable-container row">
  <div class="col-sm-12">
    <div class="table-container">
      <table class="table table-responsive">
        <thead class="thead-dark">
          <tr>
            <th scope="col">CIP</th>
            <th scope="col">Nom</th>
            <th scope="col">Quantité</th>
            <th scope="col">Prix HT</th>
            <th scope="col">TVA</th>
            <th scope="col">Prix TTC</th>
            <th scope="col">Promo</th>
            <th scope="col">Détails</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let stock of stockItems">
            <tr *ngIf="!contentLoaded">
              <td colspan="5">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                ></ngx-skeleton-loader>
              </td>
            </tr>
            <tr *ngIf="contentLoaded">
              <td>{{ stock?.product?.cipCode }}</td>
              <td class="ellipsis">{{ stock?.product?.title }}</td>
              <td>{{ stock?.quantite }}</td>
              <td>{{ stock?.price }}€</td>

              <td>
                <span class="bolder">{{ stock?.tva }}%</span>
              </td>

              <td>{{ stock?.price * (1 + stock?.tva / 100) | number: '1.2-2' }}€</td>
              <td>
                <div class="btn-wrap" *ngIf="stock.discount != '0'">
                  <span>
                    <img src="../../../../content/images/Icon feather-check-circle.svg" alt="feather-check" />
                  </span>
                </div>
              </td>
              <td>
                <div class="btn-wrap">
                  <button class="btn-command btn-view" (click)="openproductModal(modalProduct, stock)"></button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="card flex justify-content-center" *ngIf="stockItems?.length">
      <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
    </div>
    <ng-container *ngIf="!stockItems?.length">
      <lottie-player
        src="../../content/images/noData.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 250px; margin: auto"
        loop
        autoplay
      ></lottie-player>
    </ng-container>
  </div>
</div>
<ng-template #modalUpload let-modalUpload>
  <div class="modal-command">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-start">
        <h5 class="modal-title" style="margin: 0 30px; color: #0078c6">Importer un fichier CSV</h5>
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalUpload.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <form>
        <div class="row">
          <div class="col-sm-12 col-lg-4">
            <label for="ordonnance">Fichier</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" placeholder="Aucun fichier" (change)="onFileChange($event)" id="ordonnance" />
              <span class="file-placeholder">
                {{ selectedFile?.name || 'Aucun fichier sélectionné' }}
              </span>
            </div>
          </div>
        </div>
        <div class="modal-bottom"></div>
        <button class="submit" type="button" [disabled]="isUploading" (click)="onUpload(modalUpload)">
          <ng-container *ngIf="!isUploading">Confirmer</ng-container>
          <ng-container *ngIf="isUploading"> <span class="spinner-border spinner-border-sm"></span> &nbsp; En cours... </ng-container>
        </button>
        <button type="button" class="btn btn-annuler" (click)="modalUpload.dismiss('Cross click')" aria-label="Close">Annuler</button>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #modalProduct let-modalProduct>
  <form class="modal-product" [formGroup]="productForm">
    <div class="modal-header">
      <div class="col-2 d-flex justify-content-start">
        <p class="titleCIP">CIP/{{ openedProduct?.product?.cipCode }}</p>
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalProduct.dismiss('Cross click')" aria-label="Close"></button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="d-flex row align-items-center">
        <div class="col-12 col-lg-5">
          <div class="prod-img">
            <img [src]="imageUrl + openedProduct?.product?.productImage + '/large'" class="product-card prod-img-top" alt="Image" />
          </div>
          <label class="labelPromo">Promo</label>
          <label class="switch">
            <input type="checkbox" (change)="togglePromo()" />
            <span class="slider round"></span>
          </label>
          <div *ngIf="isPromoActive">
            <label for="Pourcentage" class="labelPromo">Pourcentage</label>
            <input class="form-control inputPromo" placeholder="Pourcentage" type="number" formControlName="discount" />

            <label for="promo" class="labelPromo">Prix en promo</label>
            <input class="form-control inputPromo" placeholder="Prix en promo" type="number" formControlName="priceDiscount" />
          </div>
        </div>
        <div class="col-12 col-lg-7 ml-4">
          <h3 class="name">{{ openedProduct?.product?.title }}</h3>
          <div class="product-card card-body" style="display: flex; flex-direction: column">
            <div class="d-flex flex-row align-items-center product-rating">
              <div class="d-flex flex-row">
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/star-icon.svg" alt="" />
                <img src="../../content/images/icons/star-icon.svg" alt="" />
              </div>
              <span class="available ml-3">En stock</span>
            </div>
            <div class="producer">
              <img src="../../content/images/hyfaccc-removebg-preview.png" alt="" style="width: 100%" />
            </div>
            <p class="mb-3 descriptionDetail" [ngClass]="{ expanded: isExpandedDescription }" (click)="toggleExpandDescription()">
              {{ openedProduct?.product?.description }}
            </p>
            <label for="promo" class="labelPromo">Prix</label>
            <input class="form-control inputPromo" placeholder="Prix" type="number" formControlName="price" />
            <label for="promo" class="labelPromo">Quantité</label>
            <input class="form-control inputPromo" placeholder="Quantité" type="number" formControlName="availableStock" />
            <div class="d-flex flex-row align-items-center">
              <div class="col-6 d-flex align-items-center" style="margin-right: 1rem"></div>
              <div class="col d-flex ml-3">
                <a class="btn order" (click)="updateStockItem(openedProduct?.id); modalProduct.dismiss('Cross click')">Mettre à jour</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
