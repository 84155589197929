import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PaiementsService } from '../../../paiements/paiements.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PaginatorModule } from 'primeng/paginator';
import { ToastrService } from 'ngx-toastr';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'jhi-list-payment',
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule, PaginatorModule],
  templateUrl: './list-payment.component.html',
  styleUrl: './list-payment.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ListPaymentComponent {
  paymentsList: any;
  contentLoaded = false;
  first: number = 0;
  rows: number = 1;
  totalPages: any;

  constructor(
    private paiementService: PaiementsService,
    private _toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getPaymentsList();
  }

  getPaymentsList() {
    this.paiementService.getPharmacyPayments(this.first).subscribe({
      next: res => {
        this.paymentsList = res.content;
        this.totalPages = res.totalPages;
        console.log(this.paymentsList);
      },
      error: err => {
        console.log(err);
      },
    });
  }
  onPageChange(event: any) {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 1,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getPaymentsList();
  }

  connectToStripe(): void {
    this.paiementService.stripeConnect().subscribe({
      next: response => {
        if (response && response.url) {
          window.open(response.url, '_blank');
        } else {
          console.error('URL not found in the response');
        }
      },
      error: error => {
        console.error('Failed to connect to Stripe');
        console.error('Error connecting to Stripe:', error);
      },
    });
  }
  paymentsRefund(purchaseId: any): void {
    this.paiementService.paymentsRefund(purchaseId).subscribe({
      next: response => {
        this._toastr.success('Remboursement effectué avec succès', 'Succès');
        console.log('Remboursement effectué', response);
      },
      error: error => {
        this._toastr.error('Échec du remboursement', 'Erreur');
        console.error('Erreur lors du remboursement :', error);
      },
    });
  }
}
