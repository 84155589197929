<div class="row mt-5">
  <h2 class="lead" style="color: #141414">Liste des Paiements</h2>
</div>
<br />
<div class="row">
  <div class="col-8">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Global</a>
        <ng-template ngbNavContent>
          <div>
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Date_Heure</th>
                    <th scope="col">Montant</th>
                    <th scope="col">F.Serv</th>
                    <th scope="col">F.Livr</th>
                    <th scope="col">F.Phar</th>

                    <th scope="col">ID-Com</th>
                    <th scope="col">Référence</th>
                    <th scope="col">État</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let payment of payments; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ payment?.purchase?.pharmacy?.pharmacyOrderId }}</td>
                      <td>{{ payment?.purchase?.pharmacy?.commercialName }}</td>
                      <td>{{ payment?.paymentDate | date: 'dd/MM/yyyy HH:mm' }}</td>

                      <td>{{ payment?.amount ? payment.amount / 100 + '€' : '---' }}</td>
                      <td>{{ payment?.purchase?.plateformFee }}€</td>
                      <td>{{ payment?.purchase?.deliveryFee }}€</td>
                      <td>
                        {{
                          payment?.amount
                            ? (
                                payment.amount / 100 -
                                ((payment?.purchase?.plateformFee || 0) + (payment?.purchase?.deliveryFee || 0))
                              ).toFixed(2) + '€'
                            : '---'
                        }}
                      </td>

                      <td>{{ payment?.purchase?.id }}</td>
                      <td>xxx{{ payment?.stripePaymentIntentId?.slice(-5) }}</td>
                      <td>
                        <ng-container *ngIf="payment?.purchase?.payment?.paymentStatus === 'succeeded'; else failed"> ✅ </ng-container>
                        <ng-template #failed> ❌ </ng-template>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--
          -->
            <div class="card flex justify-content-center" *ngIf="payments?.length">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
            <ng-container *ngIf="!payments?.length">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Par pharmacie</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col">
              <div class="pharma-filtre">
                <p-dropdown [options]="pharmacies" [(ngModel)]="selectedPharmacy" [filter]="true" placeholder="Sélectionnez une pharmacie">
                </p-dropdown>

                <input type="month" class="form-control pharma-filtre-input" [(ngModel)]="selectedDate" id="month" name="month" />
                <button class="btn-suivi" (click)="getPayments()">Trouver</button>
                <button class="reset" (click)="resetFilters()"><i class="fa fa-refresh"></i></button>
              </div>
            </div>
            <div class="col"></div>
          </div>

          <div class="table-container">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">CIP</th>
                  <th scope="col">Dén Commercial</th>
                  <th scope="col">C.A An</th>
                  <th scope="col">C.A Trim</th>
                  <th scope="col">C.A Mois</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let payment of payments; let i = index">
                  <tr *ngIf="!contentLoaded">
                    <td colspan="5">
                      <ngx-skeleton-loader
                        count="1"
                        appearance="line"
                        [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                      ></ngx-skeleton-loader>
                    </td>
                  </tr>
                  <tr *ngIf="contentLoaded">
                    <td>{{ payment?.purchase?.pharmacy?.pharmacyOrderId }}</td>
                    <td>{{ payment?.purchase?.pharmacy?.commercialName }}</td>
                    <td>xxxxx</td>
                    <td>xxxxx</td>

                    <td>xxxxx</td>
                    <td>
                      <div class="btn-wrap">
                        <button class="btn-payer" (click)="openModalPayment()">Voir détails</button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="card flex justify-content-center" *ngIf="payments?.length">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
          </div>
          <ng-container *ngIf="!payments?.length">
            <lottie-player
              src="../../content/images/noData.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 250px; margin: auto"
              loop
              autoplay
            ></lottie-player>
          </ng-container>
        </ng-template>
      </li>
    </ul>
  </div>
  <div class="product-search col-4">
    <input type="text" class="form-control category-input" id="text" name="search" placeholder="Recherche ..." data-cy="text" />
    <span class="search-icon" style="margin: -2rem 2rem"></span>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<ng-template #modalDetailsPayment let-modalDetailsPayment>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalDetailsPayment.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="form-container">
      <h4>Détails Paiements Livreur ID: xxxx123</h4>

      <!-- Section Gérant -->
      <p>Livreur</p>
      <div class="form-section">
        <div class="form-group">
          <input type="text" id="prenom" placeholder="Prénom & Nom" class="form-control" />
        </div>
        <div class="form-group">
          <input type="text" id="nom" placeholder="Tél Service" class="form-control" />
        </div>
      </div>
      <div class="form-section">
        <div class="form-group">
          <input type="email" id="emailService" placeholder="Heure de commande" class="form-control" />
        </div>
        <div class="form-group">
          <input type="text" id="numTelService" placeholder="Démarrage Livraison" class="form-control" />
        </div>
      </div>
      <input type="text" id="denomination" placeholder="Adresse destination" class="form-control full-width mb-3" />
      <p>Suivie de paiement</p>
      <table class="table table-responsive">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Sem</th>
            <th scope="col">DU</th>
            <th scope="col">AU</th>
            <th scope="col">Montant</th>
            <th scope="col">IBAN</th>
            <th scope="col">État</th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngIf="!contentLoaded">
              <td colspan="5">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                ></ngx-skeleton-loader>
              </td>
            </tr>
            <tr *ngIf="contentLoaded">
              <td>S_03</td>
              <td>13/01</td>
              <td>19/01</td>
              <td>95€</td>

              <td>FR124xxxxxxx123</td>
              <td>
                <div class="btn-wrap">
                  <button class="btn-payer">payé</button>
                </div>
              </td>
            </tr>
            <tr *ngIf="contentLoaded">
              <td>S_02</td>
              <td>13/01</td>
              <td>19/01</td>
              <td>135€</td>

              <td>FR124xxxxxxx123</td>
              <td>
                <div class="btn-wrap">
                  <button class="btnPayer">payé</button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <!-- Submit Button -->
    </div>
  </div>
</ng-template>
