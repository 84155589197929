<div class="d-flex justify-content-center">
  <div class="col">
    <div class="registerLogin m-auto">
      <div>
        <p style="font-size: 25px; color: #0078c6; font-weight: bold; font-family: 'ClashDisplay-Semibold'">Connexion de compte bancaire</p>
        <p style="font-family: 'ClashDisplay-Semibold'; color: #141414; font: size 18px; font-weight: bold">
          Le bouton ci-dessous vous permet de finaliser l’activation de votre compte et recevoir les paiements des clients.
        </p>
        <p style="font-family: 'ClashDisplay-Semibold'; color: #141414; font: size 18px; font-weight: bold">
          Vous serez redirigé vers la plateforme partenaire <span style="color: #0078c6">« Stripe »</span> pour activer le service
          <span style="color: #0078c6">« Connect »</span> .
        </p>
      </div>

      <button
        type="submit"
        id="save-entity"
        data-cy="entityCreateSaveButton"
        class="col-12 btn btn-primary success-btn mt-3"
        [disabled]="isLoading"
        (click)="stripeAccount()"
      >
        <span *ngIf="!isLoading" jhiTranslate="register.form.buttonCompris">Commencer</span>
        <span *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin"></i>
        </span>
      </button>

      <div class="d-flex justify-content-center m-3 flex-wrap">
        <label jhiTranslate="global.messages.info.register.haveaccount">Vous avez un compte ? </label>&nbsp;<a
          class="alert-link"
          routerLink="/login"
          jhiTranslate="global.messages.info.authenticated.link"
          >sign in</a
        >.
      </div>
    </div>
  </div>
</div>
