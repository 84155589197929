<div class="d-flex justify-content-center">
  <div class="col">
    <div class="registerLogin m-auto">
      <form name="editForm" role="form" novalidate [formGroup]="editForm" *ngIf="!ActivatedEmailAdmin">
        <h1
          id="jhi-pharmacy-heading"
          data-cy="PharmacyCreateUpdateHeading"
          jhiTranslate="pharmaApp.pharmacy.home.createLabel"
          style="margin-top: -1.2rem"
          [ngStyle]="{ 'font-size': screenHeight < 800 ? '27px' : '30px' }"
        >
          Create or edit a Pharmacy
        </h1>

        <div>
          <jhi-alert-error></jhi-alert-error>

          @if (editForm.controls.id.value !== null) {
            <div class="mb-3">
              <label class="form-label" jhiTranslate="pharmaApp.pharmacy.id" for="field_id">Id</label>
              <input type="number" class="form-control" name="id" id="field_id" data-cy="id" formControlName="id" [readonly]="true" />
            </div>
          }

          <div class="mb-3" *ngIf="!nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.DenominationCommerciale" for="field_responsibleEmail"
              >DenominationCommerciale</label
            >
            <input
              type="text"
              class="form-control"
              name="commercialName"
              id="field_commercialName"
              data-cy="commercialName"
              formControlName="commercialName"
            />
          </div>
          <!-- <div class="mb-3">
          <label class="form-label" jhiTranslate="pharmaApp.pharmacy.commercialName"
            for="field_commercialName">Commercial Name</label>
          <input type="text" class="form-control" name="commercialName" id="field_commercialName"
            data-cy="commercialName" formControlName="commercialName" />
        </div> -->
          <!-- 
        <div class="mb-3">
          <label class="form-label" jhiTranslate="pharmaApp.pharmacy.pharmacyOrderId" for="field_pharmacyOrderId">Pharmacy Order Id</label>
          <input
            type="text"
            class="form-control"
            name="pharmacyOrderId"
            id="field_pharmacyOrderId"
            data-cy="pharmacyOrderId"
            formControlName="pharmacyOrderId"
          />
        </div> -->

          <div class="mb-3" *ngIf="!nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.address" for="field_address">Address</label>
            <auto-complete
              (placeSelected)="onPlaceSelected($event)"
              [optionalInput]="editForm.get('address')?.value || ''"
              formControlName="address"
            ></auto-complete>
          </div>
          <div class="row" *ngIf="!nextValidation">
            <div class="col mb-3">
              <label class="form-label" jhiTranslate="pharmaApp.pharmacy.phoneNumber" for="field_legalName">Phone Number</label>
              <input
                type="text"
                class="form-control"
                name="phoneNumber"
                id="field_phoneNumber"
                data-cy="phoneNumber"
                formControlName="phoneNumber"
              />
            </div>

            <div class="col mb-3">
              <label class="form-label" jhiTranslate="pharmaApp.pharmacy.EmailService" for="field_responsibleEmail">Email de Service</label>
              <input
                type="text"
                class="form-control"
                name="responsibleEmail"
                id="field_responsibleEmail"
                data-cy="responsibleEmail"
                formControlName="responsibleEmail"
              />
            </div>
          </div>
          <div class="mb-3" *ngIf="nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.SaisirCIP" for="pharmacyOrderId">Saisir le CIP</label>
            <input
              type="text"
              class="form-control"
              name="pharmacyOrderId"
              id="pharmacyOrderId"
              data-cy="pharmacyOrderId"
              formControlName="pharmacyOrderId"
            />
          </div>
          <div class="mb-3" *ngIf="nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.SaisirSiret" for="siret">Saisir le Siret</label>
            <input type="text" class="form-control" name="siret" id="siret" data-cy="siret" formControlName="siret" />
          </div>
          <div class="mb-3" *ngIf="nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.SaisirIBAN" for="rib">Saisir l'IBAN</label>
            <input type="text" class="form-control" name="rib" id="rib" data-cy="rib" formControlName="rib" />
          </div>
          <!-- <div class="mb-3">
          <label class="form-label" jhiTranslate="pharmaApp.pharmacy.ownerEmail" for="field_ownerEmail">Owner Email</label>
          <input
            type="text"
            class="form-control"
            name="ownerEmail"
            id="field_ownerEmail"
            data-cy="ownerEmail"
            formControlName="ownerEmail"
          />
        </div> -->

          <!-- <div class="mb-3">
          <label class="form-label" jhiTranslate="pharmaApp.pharmacy.processingTime" for="field_processingTime">Processing Time</label>
          <input
            type="number"
            class="form-control"
            name="processingTime"
            id="field_processingTime"
            data-cy="processingTime"
            formControlName="processingTime"
          />
        </div> -->

          <div class="mb-3" *ngIf="!nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.photo" for="photo">Photo de l'officine</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event, 'file')" id="mutuelle" />
              <!-- <span class="file-placeholder">{{ editForm.get('logo')?.value || '' }}</span> -->
            </div>
          </div>

          <!-- <div class="mb-3">
          <label jhiTranslate="pharmaApp.pharmacy.categories" for="field_categories">Categories</label>
          <select
            class="form-control"
            id="field_categories"
            data-cy="categories"
            multiple
            name="categories"
            formControlName="categories"
            [compareWith]="compareCategory"
          >
            @for (categoryOption of categoriesSharedCollection; track $index) {
              <option [ngValue]="categoryOption">{{ categoryOption.id }}</option>
            }
          </select>
        </div> -->
        </div>

        <div>
          <!-- <button type="button" id="cancel-save" data-cy="entityCreateCancelButton" class="btn btn-secondary"
          (click)="previousState()">
          <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
        </button> -->

          <button
            *ngIf="!nextValidation"
            (click)="nextValidationPage()"
            id="save-entity"
            data-cy="entityCreateSaveButton"
            [disabled]="editForm.invalid || isSaving"
            class="col-12 btn btn-primary success-btn mt-3"
          >
            <span jhiTranslate="register.form.buttonNext">Suivant</span>
          </button>
          <button
            *ngIf="nextValidation"
            type="submit"
            (click)="login()"
            id="save-entity"
            data-cy="entityCreateSaveButton"
            [disabled]="editForm.invalid || isSaving"
            class="col-12 btn btn-primary success-btn mt-3"
          >
            <span jhiTranslate="register.form.buttonNext">Suivant</span>
          </button>
        </div>
      </form>
      <div *ngIf="ActivatedEmailAdmin">
        <p style="font-size: 25px; color: #83b011; font-weight: bold; font-family: 'ClashDisplay-Semibold'">Merci!</p>
        <p style="font-family: 'ClashDisplay-Semibold'; color: #141414; font: size 18px; font-weight: bold">
          Votre compte est soumis à la révision de l’administrateur pour validation.
        </p>
        <p style="font-family: 'ClashDisplay-Semibold'; color: #141414; font: size 18px; font-weight: bold">
          Une notification de votre demandé est envoyé à l’administrateur de la plateforme.
        </p>
        <p style="font-family: 'ClashDisplay-Semibold'; color: #141414; font: size 18px; font-weight: bold">
          Vous allez recevoir un mail dans un délais de 2 jours ouvrables pour vous permettre de finaliser l’activation de votre compte.
        </p>
      </div>

      <button
        *ngIf="ActivatedEmailAdmin"
        type="submit"
        id="save-entity"
        data-cy="entityCreateSaveButton"
        class="col-12 btn btn-primary success-btn mt-3"
        (click)="navigateToLanding()"
      >
        <span jhiTranslate="register.form.buttonCompris" (click)="navigateToLanding()">J'ai compris</span>
      </button>
      <div class="d-flex justify-content-center m-3 flex-wrap">
        <label jhiTranslate="global.messages.info.register.haveaccount">Vous avez un compte ? </label>&nbsp;<a
          class="alert-link"
          routerLink="/login"
          jhiTranslate="global.messages.info.authenticated.link"
          >sign in</a
        >.
      </div>
    </div>
  </div>
</div>
