import { CartService } from '../cart/cart.service';
import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild, NgZone, ChangeDetectionStrategy, OnInit, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeServiceService } from '../home/service/home-service.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../core/auth/account.service';

@Component({
  selector: 'jhi-cart',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CartComponent implements OnInit {
  isExpanded = false;
  cartItems: any[] = [];
  isExpandedDescription = false;
  subtotal: number = 0;
  deliveryFee: number = 0;
  serviceFee: number = 0;
  total: number = 0;
  clicAndCollect: boolean = false;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  cookiesCart!: any[];
  purchase_id: any;
  pharmacyId: any;
  account: any = {};
  selectedAddressId: number | null = null;
  selectedAddress: any = {};
  pharmacyName: any;
  pharmacyAddress: any;
  numCommande: any;
  blocCommand: boolean = false;
  deliveryDetails: boolean = false;
  paymentDetails: boolean = false;
  PurchasesForm: FormGroup;
  subCategoryId: any;
  prescriptionFiles: File[] = [];
  vitalCardFiles: File[] = [];
  mutualCardFiles: File[] = [];

  isFilesExist: Boolean = false;
  testing: any = null;
  ordonnanceImages: Array<string | ArrayBuffer> = [];
  @ViewChild('confirmedAddOrder') confirmedAddOrder!: any;
  @ViewChild('modalOrdonnance') modalOrdonnance!: any;
  @ViewChild('scrollTarget') scrollTarget!: ElementRef;
  file!: File; // File object
  fileUrl!: string; // URL string
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private router: Router,
    private ngZone: NgZone,
    private pharmacieService: HomeServiceService,
    private toastr: ToastrService,
    private accountService: AccountService,
  ) {
    this.PurchasesForm = this.createPurchasesForm();
  }
  confirmAndScroll(): void {
    this.PurchasesFees();
    this.plateformFee();
    this.addOrPutOrder();
    this.nextStepToDeliveryDetails();
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }
  ngOnInit() {
    this.cartService.purchaseId$.subscribe(purchaseId => {
      this.purchase_id = purchaseId;
    });
    const selectedAddressId = localStorage.getItem('selectedAddressId');
    if (selectedAddressId) {
      this.selectedAddressId = parseInt(selectedAddressId, 10);
    }

    this.loadAddresses();
    window.scrollTo(0, 0);
    this.loadCartItems();
    this.pharmacyId = this.cartService.getPharmacy();
    this.loadContactInfo();
    this.pharmacyName = this.pharmacieService.getCommercialName();
    this.pharmacyName = this.cartService.getPharmacyName();

    this.pharmacyAddress = this.cartService.getPharmacyAddress();
    this.purchase_id = this.cartService.getPurchaseId();
    this.subCategoryId = this.pharmacieService.getSubCatId();
    this.getAllFilesUrl();
    this.getAllFilesUrlFromOvh();
    this.loadProfile();
  }
  fileUrlsFromOvh: string[] = [];
  isFilesExistFromOvh = false;
  prescriptionFilesFromOvh: any[] = [];
  vitalCardFilesFromOvh: any[] = [];
  mutualCardFilesFromOvh: any[] = [];
  getAllFilesUrlFromOvh(): void {
    const purchaseId = this.cartService.getPurchaseId();
    this.pharmacieService.getFileUrl(purchaseId).subscribe(res => {
      console.log('Réponse du serveur:', res); // Vérifiez la réponse du serveur
      this.prescriptionFilesFromOvh = res.prescription || [];
      this.vitalCardFilesFromOvh = res.vital_card || [];
      this.mutualCardFilesFromOvh = res.mutual_card || [];

      const files = [...res.prescription, ...res.vital_card, ...res.mutual_card];
      console.log('Clés de fichiers:', files); // Vérifiez les clés de fichiers

      if (files.length > 0) {
        this.isFilesExistFromOvh = true;
        this.prescriptionFilesFromOvh = res.prescription || [];
        this.fileUrlsFromOvh = [];
        console.log("Fichiers d'ordonnance:", this.prescriptionFilesFromOvh); // Vérifiez les fichiers d'ordonnance
        files.forEach((fileKey, index) => {
          this.pharmacieService
            .getImage('test-documents-cmd-ordonnance', fileKey)
            .then(data => {
              const blob = new Blob([data.Body], { type: data.ContentType });
              const url = URL.createObjectURL(blob);
              this.fileUrlsFromOvh.push(url);
              console.log('URL de fichier:', url); // Vérifiez les URL créées

              if (this.prescriptionFiles.includes(fileKey)) {
                this.prescriptionFilesFromOvh[index] = url;
              } else if (this.vitalCardFiles.includes(fileKey)) {
                this.vitalCardFilesFromOvh[index] = url;
              } else if (this.mutualCardFiles.includes(fileKey)) {
                this.mutualCardFilesFromOvh[index] = url;
              }
            })
            .catch(err => {
              console.error("Erreur lors du chargement de l'image :", err);
            });
        });
      }
    });
  }
  prescriptionUrls: string[] = [];
  vitalCardUrl: string | null = null;
  mutualCardUrl: string | null = null;
  getAllFilesUrl(): void {
    const purchaseId = this.cartService.getPurchaseId();
    this.pharmacieService.getFileUrl(purchaseId).subscribe(res => {
      this.documents = res;
      this.testing = res.prescription;
      this.prescriptionUrls = res.prescription || [];
      this.vitalCardUrl = res.vital_card || null;
      this.mutualCardUrl = res.mutual_card || null;
      console.log(`Files URL: ${res}`);
    });
  }
  getFileUrl(file: File): string {
    return URL.createObjectURL(file);
  }
  deleteFilesUrl(): void {
    const purchaseId = this.cartService.getPurchaseId();
    const prescription = this.testing;
    this.pharmacieService.deleteFile(purchaseId, prescription).subscribe(
      response => {
        console.log('File deleted successfully:', response);
        this.deletePrescription();
        this.loadCartItems();
        this.getAllFilesUrl(); // Refresh the file list
      },
      error => {
        console.error('Error deleting file:', error);
      },
    );
  }
  deletePrescription(): void {
    this.cartService.removePrescriptionFromCookies();
  }

  createPurchasesForm(data?: any): FormGroup {
    return this.formBuilder.group({
      firstName: [data ? data.firstName : ''],
      lastName: [data ? data.lastName : ''],
      prescription: [data ? data.prescription : null],
      mutual_card: [data ? data.mutual_card : null],
      vital_card: [data ? data.vital_card : null],
    });
  }

  onFileSelected(event: Event, fileType: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.PurchasesForm.get(fileType)?.setValue(file);
      const files = Array.from(inputElement.files);

      if (fileType === 'prescription') {
        this.prescriptionFiles = [...this.prescriptionFiles, ...files];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.ordonnanceImages.push(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        const spanElement = inputElement.nextElementSibling as HTMLElement;
        if (spanElement) {
          spanElement.textContent = file.name;
        }
      }
      files.forEach(file => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (fileType === 'prescription') {
            this.ordonnanceImages.push(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  }

  removeFile(fileToRemove: File): void {
    this.prescriptionFiles = this.prescriptionFiles.filter(file => file !== fileToRemove);
  }
  NavigateToProductsOrPharmacy() {
    if (this.subCategoryId) {
      this.router.navigate(['/pharmacie-products', { pharmacieId: this.pharmacyId, subCategoryId: this.subCategoryId }]);
    } else {
      this.router.navigate(['/pharmacie-category', { pharmacieId: this.pharmacyId, T: 'P' }]);
    }
  }
  NavigateToHome() {
    this.router.navigate(['/home']);
  }
  addPrescription(): void {
    this.PurchasesForm.markAllAsTouched();
    this.PurchasesForm.markAsDirty();
    const formData = new FormData();

    // Ajouter les fichiers d'ordonnance (nouvelles ou existantes)
    this.prescriptionFiles.forEach((file, index) => {
      formData.append('prescription', file);
    });

    // Ajouter les autres fichiers (Carte Vitale, Carte Mutuelle)
    ['vital_card', 'mutual_card'].forEach(fileType => {
      const file = this.PurchasesForm.get(fileType)?.value;
      if (file && file instanceof File) {
        formData.append(fileType, file);
      } else if (this.isFilesExistFromOvh) {
        const existingFileUrl = this.getExistingFileUrl(fileType);
        if (existingFileUrl) {
          formData.append(fileType, existingFileUrl);
        }
      }
    });

    this.uploadFiles(formData);
  }

  getExistingFileUrl(fileType: string): string | null {
    switch (fileType) {
      case 'vital_card':
        return this.vitalCardFilesFromOvh.length > 0 ? this.vitalCardFilesFromOvh[0] : null;
      case 'mutual_card':
        return this.mutualCardFilesFromOvh.length > 0 ? this.mutualCardFilesFromOvh[0] : null;
      default:
        return null;
    }
  }

  uploadFiles(formData: FormData): void {
    const purchaseId = this.cartService.getPurchaseId();
    this.pharmacieService.uploadFiles(purchaseId, formData).subscribe({
      next: (res: any) => {
        console.log('Response for file upload:', res);
        this.toastr.success('Fichiers téléchargés avec succès');
        this.getAllFilesUrl();
        this.resetForm();
        this.cartService.addPrescriptionToCart();
      },
      error: (err: any) => {
        console.log('Error uploading files', err);
        this.toastr.error('Erreur lors du téléchargement des fichiers');
      },
    });
  }

  resetForm() {
    this.prescriptionFiles = [];
    this.fileUrls = [];
    this.vitalCardFiles = [];
    this.mutualCardFiles = [];
    this.PurchasesForm?.reset();
  }
  maxFileSize = 5 * 1024 * 1024; // 5 MB
  allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  maxFiles = 3;
  fileUrls: string[] = [];

  onFileSelectedPrescription(event: any): void {
    const files: FileList = event.target.files;

    // Check if number of files exceeds the limit
    if (this.prescriptionFiles.length + files.length > this.maxFiles) {
      this.toastr.info(`You can only upload a maximum of ${this.maxFiles} files.`, 'File Limit Exceeded');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Validate file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.toastr.info('Only images (JPG, PNG) or PDFs are allowed.', 'Invalid File Type');
        continue;
      }

      // Validate file size
      if (file.size > this.maxFileSize) {
        this.toastr.info(`File size should not exceed ${this.maxFileSize / (1024 * 1024)} MB.`, 'File Size Exceeded');
        continue;
      }

      // Add file to list if valid
      this.prescriptionFiles.push(file);

      // Use FileReader to generate preview URL
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileUrls.push(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  // removeFilePrescription(file: File): void {
  //   const index = this.prescriptionFiles.indexOf(file);
  //   if (index > -1) {
  //     this.prescriptionFiles.splice(index, 1);
  //     this.fileUrls.splice(index, 1);
  //   }
  // }
  removeFilePrescription(fileUrl: string, index: number): void {
    this.prescriptionFiles.splice(index, 1);
    this.fileUrls.splice(index, 1);
    this.fileUrlsFromOvh.splice(index, 1);
  }
  consentGiven = false;
  documents: any = {} as {
    mutual_card: [];
    prescription: [];
    vital_card: [];
  };
  formatNumCommande(num: string): string {
    return num ? num.replace(/^phgo-/, '') : '---';
  }
  validDocuments: boolean = false;

  onCheckboxChange(event: any): void {
    this.consentGiven = event.target.checked; // Mettez à jour l'état de la case à cocher
  }
  accountClient: any = {};
  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.accountClient = res;
    });
  }

  loadContactInfo(): void {
    this.pharmacieService.getContactInfo(this.purchase_id).subscribe(data => {
      this.account = data;

      // Si certains champs sont vides, utilisez les données de secours depuis accountClient
      this.account.email = this.account.email || this.accountClient.email;
      this.account.firstname = this.account.firstname;
      this.account.lastname = this.account.lastname;
      this.account.phoneNumber = this.account.phoneNumber || this.accountClient.applicationUser?.phoneNumber;
    });
  }
  recentAddress: any = {};
  loadAddresses() {
    this.pharmacieService.getAdresse().subscribe(
      data => {
        this.selectedAddress = data;
        this.recentAddress = this.selectedAddress
          .filter((address: any) => address.lastUsedDate)
          .sort((a: any, b: any) => new Date(b.lastUsedDate).getTime() - new Date(a.lastUsedDate).getTime())[0];
        console.log('Dernière adresse utilisée', this.recentAddress);
      },
      error => {
        this.toastr.error('Erreur lors de la récupération des adresses');
      },
    );
  }
  updateContactInfo(): void {
    const contactInfo = {
      firstname: this.accountClient.firstName,
      lastname: this.accountClient.lastName,
      email: this.accountClient.email,
      phoneNumber: this.accountClient.applicationUser?.phoneNumber,
      address: this.recentAddress,
      clickAndCollect: this.clicAndCollect,
    };

    this.pharmacieService.updateContactInfo(this.purchase_id, contactInfo).subscribe({
      next: (response: any) => {
        this.toastr.success('Contact information updated successfully');
        console.log('Contact information updated successfully', response);
        this.loadContactInfo();
      },
      error: err => {
        this.toastr.error('Erreur lors de la modification des informations');
        console.error('Erreur lors de la modification des informations :', err);
      },
    });
  }
  feasible: any;
  plateformFee() {
    this.cartService.getPlateformFee().subscribe({
      next: (res: any) => {
        this.serviceFee = res;
        this.calculateSubtotal();
        console.log('delivery estimate successfully');
      },
      error: (err: any) => {
        console.log('error', err.error);
      },
    });
  }
  PurchasesFees(): void {
    console.log('PurchasesFees');
    const coords = this.cartService.getSelectedAddressCoordinates();
    const longitude = this.pharmacieService.getLongitude();
    const latitude = this.pharmacieService.getLatitude();

    if (coords) {
      const { lat, lng } = coords;
      const body = {
        expLongitude: longitude,
        expLatitude: latitude,
        deliveryLongitude: lng,
        deliveryLatitude: lat,
      };
      this.cartService.PurchasesFees(body).subscribe({
        next: (res: any) => {
          this.deliveryFee = res.estimatedCost;
          this.calculateSubtotal();
          console.log('delivery estimate successfully');
          if (res.feasible === false) {
            this.toastr.error("Cette zone de livraison n'est pas desservie. Veuillez changer votre adresse.");
          }
        },
        error: (err: any) => {
          console.log('error', err.error);
          if (err.error.feasible === false) {
            this.toastr.error("Cette zone de livraison n'est pas desservie. Veuillez changer votre adresse.");
          }
        },
      });
    }
  }
  checkoutCommande() {
    const purchaseId = this.cartService.getPurchaseId();
    this.confirmOrder(purchaseId);
  }
  confirmOrder(purchaseId: number) {
    const payload = {
      event: 'CLIENT_CONFIRM',
      purchaseId: purchaseId,
      deliveryAddressId: this.selectedAddressId,
    };

    this.cartService.clientConfirmOrder(payload).subscribe({
      next: (response: any) => {
        console.log('Commande confirmée avec succès : ', response.name);
        if (response.name == 'CREATED_WITH_PRESCRIPTION') {
          console.log(response.name);
          this.openConfirmedAddOrder();
        } else {
          response.url;
          this.cartService.clearCartItems();
          window.open(response.url);
          this.router.navigate(['/home']);
        }
      },
      error: err => {
        console.error('Erreur lors de la confirmation de la commande : ', err);
      },
    });
  }
  openConfirmedAddOrder() {
    this.modalService.open(this.confirmedAddOrder, { size: 's', centered: true });
  }
  navigateToOrdersList() {
    this.cartService.clearCartItems();
    this.router.navigateByUrl(`/orders`);
  }
  addOrPutOrder() {
    if (this.purchase_id) {
      this.putOrder();
    } else this.addOrder();
  }
  checkAndAddPurchases() {
    this.addPrescription();
  }

  addOrder() {
    this.cartService.setTotalPrice(Number(this.total.toFixed(2)));
    let purchaseStockItems: any = [];
    this.cookiesCart.forEach(async item => {
      purchaseStockItems.push({
        stockItem: { id: item.id },
        requestedQuantity: item.quantity,
      });
    });
    const cartData = {
      pharmacyId: this.cartService.getPharmacy(),
      deliveryAddressId: this.selectedAddressId,
      purchaseStockItems: purchaseStockItems,
    };
    this.pharmacieService.addToCart(cartData).subscribe(
      response => {
        this.cartService.setPurchaseId(response.id);
        this.cartService.setNumCommande(response.num);
        this.blocCommand = true;
        this.deliveryFee = response.deliveryFee;
        this.serviceFee = response.plateformFee;
      },
      error => {
        console.error('Error adding product to cart:', error);
      },
    );
  }
  putOrder() {
    this.cartService.setTotalPrice(Number(this.total.toFixed(2)));
    let purchaseStockItems: any = [];

    this.cookiesCart.forEach(item => {
      const stockItem = item.id ? { id: item.id } : undefined;
      if (stockItem) {
        purchaseStockItems.push({
          stockItem: stockItem,
          requestedQuantity: item.quantity,
        });
      }
    });

    const cartData = {
      deliveryAddressId: this.selectedAddressId,
      purchaseStockItems: purchaseStockItems,
    };

    this.pharmacieService.putCart(cartData, this.purchase_id).subscribe(
      response => {
        this.cartService.setPurchaseId(response.id);
        this.cartService.setNumCommande(response.num);
        this.blocCommand = true;
      },
      error => {
        console.error('Error adding product to cart:', error);
      },
    );
  }

  // putOrder() {
  //   this.cartService.setTotalPrice(Number(this.total.toFixed(2)));
  //   let purchaseStockItems: any = [];
  //   this.cookiesCart.forEach(async item => {
  //     purchaseStockItems.push({
  //       stockItem: { id: item.id },
  //       requestedQuantity: item.quantity,
  //     });
  //   });
  //   const cartData = {
  //     purchaseStockItems: purchaseStockItems,
  //   };
  //   this.pharmacieService.putCart(cartData, this.purchase_id).subscribe(
  //     response => {
  //       this.cartService.setPurchaseId(response.id);
  //       this.cartService.setNumCommande(response.num);
  //       this.blocCommand = true;
  //     },
  //     error => {
  //       console.error('Error adding product to cart:', error);
  //     },
  //   );
  // }
  toggleExpandDescription() {
    this.isExpandedDescription = !this.isExpandedDescription;
  }
  nextStepToDeliveryDetails() {
    this.deliveryDetails = true;
  }
  nextStepToPaymentDetails() {
    this.paymentDetails = true;
  }

  loadCartItems(): void {
    this.cartService.cartItems$.subscribe(cartItems => {
      this.cookiesCart = cartItems;
      this.cartItems = cartItems;
      let itemsProcessed = 0;
      if (cartItems.length === 0) {
        this.calculateSubtotal();
        return;
      }
      this.calculateSubtotal();
    });
  }

  calculateSubtotal(): void {
    this.subtotal = 0;
    this.cartItems.forEach(item => {
      this.subtotal += item.price * item.quantity;
    });
    this.calculateTotal();
  }
  onClicAndCollectChange(event: any): void {
    if (this.clicAndCollect) {
      this.deliveryFee = 0;
    } else {
      this.deliveryFee = 5;
    }
    this.loadCartItems();
  }

  calculateTotal(): void {
    this.deliveryFee = this.clicAndCollect ? 0 : this.deliveryFee;
    this.total = Number(this.subtotal) + Number(this.deliveryFee) + Number(this.serviceFee);
  }
  openCommandModal(modal: any) {
    this.modalService.open(modal, { size: 'l', centered: true });
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
  removeItem(index: any) {
    this.cartItems.splice(index, 1);
    this.cartService.deleteFromCart(index);
  }
  clearCart(): void {
    this.cartService.clearCart();
    this.deleteFilesUrl();
    this.NavigateToHome();
  }

  increaseQty(id: any) {
    this.cartService.increaseQuantity(id);
    this.calculateSubtotal();
  }

  decreaseQty(id: any) {
    this.cartService.decreaseQuantity(id);
    this.calculateSubtotal();
  }
  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }
  ngOnDestroy() {
    if (this.fileUrl) {
      URL.revokeObjectURL(this.fileUrl);
    }
  }
}
