import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaiementsService {
  constructor(private http: HttpClient) {}

  getPayments(page: any, size: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/payments/my-payments?page=${page}&size=${size}`);
  }
  getPharmacyPayments(page: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/payments/pharmacy-payments?page=${page}`);
  }
  stripeConnect(): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/stripe/create-login-link`, {});
  }
  paymentsRefund(purchaseId: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/payments/refund/${purchaseId}`, {});
  }
}
