import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  getAllPayments(first: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/payments/all?page=${first}&size=8`);
  }
  getAllPharmacy(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/pharmacies/all`);
  }
  getPaymentByPharmacy(first: any, idPharmacy: any, startDate: any, endDate: any): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/payments/pharmacy-payments/${idPharmacy}?start_date=${startDate}&end_date=${endDate}&page=${first}&size=8`,
    );
  }
}
