import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(private http: HttpClient) {}

  addBusinessHours(headers: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/business-hours`, headers);
  }
  putBusinessHours(id_schedule: number, headers: any): Observable<any> {
    return this.http.patch(`${environment.baseUrl}api/business-hours/${id_schedule}`, headers);
  }
  getBusinessHours(pharmacy_id: any) {
    return this.http.get<any>(`${environment.baseUrl}api/business-hours/pharmacy/${pharmacy_id}`);
  }
  getBusinessHoursPeriod(pharmacy_id: any, fromdate: any, todate: any) {
    return this.http.get<any>(`${environment.baseUrl}api/business-hours/pharmacy/${pharmacy_id}/date/${fromdate}`);
  }
  deleteBusinessHours(id_schedule: any): Observable<HttpResponse<{}>> {
    return this.http.delete(`${environment.baseUrl}api/business-hours/${id_schedule}`, { observe: 'response' });
  }

  addExceptionalDays(headers: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/exceptional-days`, headers);
  }
  putExceptionalDays(id_schedule: number, headers: any): Observable<any> {
    return this.http.patch(`${environment.baseUrl}api/exceptional-days/${id_schedule}`, headers);
  }
  getExceptionalDays(pharmacy_id: any) {
    return this.http.get<any>(`${environment.baseUrl}api/exceptional-days/${pharmacy_id}`);
  }
  getExceptionalDaysPeriod(pharmacy_id: any, fromdate: any, todate: any) {
    return this.http.get<any>(`${environment.baseUrl}api/exceptional-days/pharmacy/${pharmacy_id}/from/${fromdate}/to/${todate}`);
  }
  deleteExceptionalDays(id_schedule: any): Observable<HttpResponse<{}>> {
    return this.http.delete(`${environment.baseUrl}api/exceptional-days/${id_schedule}`, { observe: 'response' });
  }
  getAllCalendar(pharmacy_id: any, fromdate: any, todate: any) {
    return this.http.get<any>(`${environment.baseUrl}api/calendar/pharmacy/${pharmacy_id}/from/${fromdate}/to/${todate}`);
  }
}
