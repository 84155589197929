<div class="row mt-5 mb-4">
  <div class="col-sm-12 col-md-5">
    <h2 class="lead" style="color: #0078c6">
      <img
        (click)="navigateToPurchaseLite()"
        src="../../../../content/images/icons/arrow-left-solid-blue.svg"
        alt="upload"
        width="18px"
        style="margin-right: 10px; cursor: pointer"
      />
      Détails Commande
    </h2>
  </div>
  <div class="col-sm-12 col-md-7 d-sm-flex justify-content-md-end flex-column flex-sm-row mt-3">
    <span class="command-id">ID : {{ purchaseDetails?.id }}</span>
    <span
      class="badge"
      [ngClass]="{
        'bg-new': getUserStatus(purchaseDetails?.lastStatus?.name) === 'NOUVELLE',
        'bg-in-progress': getUserStatus(purchaseDetails?.lastStatus?.name) === 'EN COURS',
        'bg-prete': getUserStatus(purchaseDetails?.lastStatus?.name) === 'PRETE',
        'bg-livraison': getUserStatus(purchaseDetails?.lastStatus?.name) === 'EN LIVRAISON',
        'bg-archived': getUserStatus(purchaseDetails?.lastStatus?.name) === 'ARCHIVE'
      }"
      >{{ getUserStatus(purchaseDetails?.lastStatus?.name) }}</span
    >
  </div>
</div>
<div class="row">
  <div class="container">
    <ul ngbNav #navCommandDetails="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="6">
        <a ngbNavLink>Frais</a>
        <ng-template ngbNavContent>
          <div class="table-container">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Titre</th>
                  <th scope="col">Prix Unitaire</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Prix TTC</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Produits/Médicaments</td>
                  <td>{{ purchaseDetails?.productsAmount.toFixed(2) }}€</td>
                  <td>1</td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.productsAmount.toFixed(2) }}€</span>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-delete"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Frais de service</td>
                  <td>{{ purchaseDetails?.plateformFee }}€</td>
                  <td>---</td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.plateformFee }}€</span>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <!--<button class="btn-command btn-edit"></button>-->
                      <button class="btn-command btn-delete"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Livraison</td>
                  <td>{{ purchaseDetails?.deliveryFee }}€</td>
                  <td>---</td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.deliveryFee }}€</span>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <!--<button class="btn-command btn-edit"></button>-->
                      <button class="btn-command btn-delete"></button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="purchaseDetails?.prescriptionExtraAmount">
                  <td>Supplément</td>
                  <td></td>
                  <td></td>
                  <td>
                    <span class="bolder">{{ purchaseDetails?.prescriptionExtraAmount }}€</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td style="background-color: #fbfbfb"><span class="total">Totale</span></td>
                  <td style="background-color: #fbfbfb">
                    <span class="total-price">{{ purchaseDetails?.totalAmount.toFixed(2) }}€</span>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="7">
        <a ngbNavLink>Détails</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="card">
                <div class="card-body">
                  <form action="">
                    <div class="mb-3 row">
                      <div class="col-sm-6">
                        <label for="firstName">Prénom</label>
                        <input type="text" name="firstName" [value]="purchaseDetails.dest.firstname" id="firstName" />
                      </div>
                      <div class="col-sm-6">
                        <label for="lastName">Nom</label>
                        <input type="text" name="lastName" id="lastName" [value]="purchaseDetails.dest.lastname" />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="col-sm-6">
                        <label for="tel">Num Tél</label>
                        <input type="tel" name="tel" id="tel" [value]="purchaseDetails.dest.phoneNumber" />
                      </div>
                      <div class="col-sm-6">
                        <label for="mail">Mail</label>
                        <input type="email" name="mail" id="mail" [value]="purchaseDetails.dest.email" />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="col-sm-12">
                        <label for="address">Adresse</label>
                        <input
                          type="text"
                          name="address"
                          id="address"
                          [value]="
                            purchaseDetails.deliveryAddress
                              ? purchaseDetails.deliveryAddress.streetNumber +
                                ' ' +
                                purchaseDetails.deliveryAddress.city +
                                ' ' +
                                purchaseDetails.deliveryAddress.state +
                                ', ' +
                                purchaseDetails.deliveryAddress.postalCode +
                                ', ' +
                                purchaseDetails.deliveryAddress.country
                              : ''
                          "
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 mt-3 mt-md-0">
              <div class="card mb-3" *ngIf="fileUrlsFromOvh.length || purchaseDetails.purchaseStockItems.length">
                <div class="card-body">
                  <div class="command-summary">
                    <div class="d-flex flex-column" *ngIf="fileUrlsFromOvh.length">
                      <div class="">
                        <label for="ordonnance">Ordonnance</label>
                        <button class="btn-command btn-edit" style="float: right" (click)="openCommandModal(modalOrdonnance)"></button>
                        <div class="documents">
                          <div *ngFor="let fileUrl of fileUrlsFromOvh; let i = index" class="document">
                            <p-image [src]="fileUrl" alt="Image" class="img-fluid" [preview]="true"> </p-image>
                            <button class="expand-btn" (click)="downloadImage(fileUrl, 'image-' + i + '.jpg')">&#x21F2;</button>
                            <!--
                          --></div>
                        </div>
                      </div>
                    </div>
                    <label for="ordonnance" *ngIf="purchaseDetails.purchaseStockItems.length">Produits</label>
                    <div class="summary-item" *ngFor="let product of purchaseDetails.purchaseStockItems">
                      <p *ngIf="purchaseDetails.purchaseStockItems.length">
                        <b>{{ product?.requestedQuantity }} </b>
                        X
                        <b>{{ product?.stockItem?.product?.title }} </b>
                        <b class="description-truncated">{{ product?.stockItem?.product?.description }}</b>
                        <b>{{ product?.stockItem?.price }}€ </b>
                      </p>
                      <!--
                      <p *ngIf="purchaseDetails.purchaseStockItems.length">
                        {{ product?.stockItem?.product?.cipCode }}
                      </p>
                      -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="command-summary">
                    <div class="d-flex flex-column">
                      <div class="summary">
                        <div class="summary-item">
                          <p>
                            Pharmacie : <b>{{ purchaseDetails?.pharmacy?.legalName }}</b>
                          </p>
                        </div>
                        <div class="summary-item">
                          <p>
                            Num commande : <b>{{ purchaseDetails?.id }}</b>
                          </p>
                        </div>
                        <div class="summary-item" *ngIf="purchaseDetails?.clickAndCollect === false">
                          <p>Type de livraison : <b>Livraison à domicile</b></p>
                        </div>
                        <div class="summary-item" *ngIf="purchaseDetails?.clickAndCollect === true">
                          <p>Type de livraison : <b>Click And Collect</b></p>
                        </div>
                        <div class="summary-item summary-total">
                          <p>
                            Totale de la commande : <span class="total-price">{{ purchaseDetails?.totalAmount.toFixed(2) }}€</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  <button class="btn btn-confirm btn-block mt-3" (click)="updateProfile()">METTRE À JOUR</button> -->
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="8">
        <a ngbNavLink>Notification</a>
        <ng-template ngbNavContent>
          <div class="chat-wrapper">
            <div class="chat-box" #messageContainer>
              <div class="msges">
                <div class="msg_history">
                  <div
                    *ngFor="let msg of messages"
                    class="d-flex"
                    [ngClass]="{
                      'justify-content-end': msg.isCurrentUser,
                      'justify-content-start': !msg.isCurrentUser
                    }"
                  >
                    <div class="incoming_msg_img" *ngIf="!msg.isCurrentUser">
                      <img [src]="msg.avatar" alt="receiver avatar" />
                    </div>
                    <div class="received_msg" *ngIf="!msg.isCurrentUser">
                      <p>{{ msg.text }}</p>
                      <span class="time_date">{{ formattedCreatedDate(msg.createdAt) }}</span>
                    </div>
                    <div class="sent_msg" *ngIf="msg.isCurrentUser">
                      <p>{{ msg.text }}</p>
                      <span class="time_date">{{ formattedCreatedDate(msg.createdAt) }}</span>
                    </div>
                    <div class="outgoing_msg_img" *ngIf="msg.isCurrentUser">
                      <img [src]="msg.avatar" alt="sender avatar" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chat-input-wrapper">
              <input type="text" [(ngModel)]="message" placeholder="Type your message here..." (keydown.enter)="sendMessage()" />
              <button class="send-msge" (click)="sendMessage()"></button>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="navCommandDetails" class="mt-2"></div>
  </div>
</div>

<ng-template #modalOrdonnance let-modalOrdonnance>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-start">
        <div class="title">
          Nouvelle Commande chez {{ pharmacyName }}
          <p class="command-id" *ngIf="numCommande">ID: {{ numCommande }}</p>
        </div>
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalOrdonnance.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <form [formGroup]="PurchasesForm">
        <div class="row">
          <div class="col-sm-12 col-lg-3">
            <label for="ordonnance">Ordonnance</label>
            <div class="file-wrapper">
              <div class="add-date-btn">
                <input class="form-control" type="file" (change)="onFileSelectedPrescription($event)" id="ordonnance" multiple />
                <span class="file-placeholder-ordonnance"></span>
                Téléversez vos ordonnances
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-9 d-flex align-items-center">
            <div *ngIf="fileUrls.length > 0 || fileUrlsFromOvh.length > 0" class="image-wrapper d-flex">
              <div class="image-container" *ngFor="let fileUrl of fileUrlsFromOvh; let i = index">
                <img [src]="fileUrl" alt="Ordonnance Image" class="img-fluid" />
                <button type="button" (click)="removeFilePrescription(fileUrl, i)" class="btn btn-danger btn-sm">X</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <label for="carte-vitale">Carte Vitale</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event, 'vital_card')" id="carte-vitale" />
              <span class="file-placeholder">{{ PurchasesForm.get('vital_card')?.value?.name || '' }}</span>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6">
            <label for="mutuelle">Carte mutuelle</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event, 'mutual_card')" id="mutuelle" />
              <span class="file-placeholder">{{ PurchasesForm.get('mutual_card')?.value?.name || '' }}</span>
            </div>
          </div>
        </div>
        <div class="modal-bottom">
          <label for="consentement" class="consentement">
            <input id="consentement" type="checkbox" name="consentement" class="form-check-input" (change)="onCheckboxChange($event)" />
            Je valide mon consentement pour le traitement de mes données personnelles.
          </label>
        </div>
        <button
          class="submit"
          type="button"
          [disabled]="!consentGiven"
          (click)="checkAndAddPurchases(); modalOrdonnance.dismiss('Cross click')"
        >
          Confirmer
        </button>
        <div #scrollTarget></div>
        <button type="button" class="btn btn-annuler" (click)="modalOrdonnance.dismiss('Cross click')" aria-label="Close">Annuler</button>
      </form>
    </div>
  </div>
</ng-template>
