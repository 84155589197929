import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from '../service/home-service.service';
import { ThisReceiver } from '@angular/compiler';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import SharedModule from '../../shared/shared.module';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CartService } from '../../cart/cart.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'jhi-pharmacie-details',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule, NgxSkeletonLoaderModule, ReactiveFormsModule],
  templateUrl: './pharmacie-details.component.html',
  styleUrl: './pharmacie-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PharmacieDetailsComponent implements OnInit {
  @ViewChild('modalProductExist') modalProductExist: any;

  currentPage: number = 1;
  categories: any;
  pharmacyId!: number;
  pharmacyType: any;
  pharmacyName: any;
  contentLoaded = false;
  activeCategory: string = 'P';
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  promos: any;
  PurchasesForm: FormGroup;
  selectedAddressId: number | null = null;
  maxFileSize = 5 * 1024 * 1024; // 5 MB
  allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  maxFiles = 3;
  fileUrls: string[] = [];
  isExpandedDescription = false;
  isExpanded = false;
  openedProduct: any;
  value: number = 1;
  activeTab: string = 'composition';
  pharmacyCartItemsName: any;

  productsSubCateg = [
    {
      name: 'Fotoker crème contrôle des dommages actiniques t doseur/50ml',
      price: 19.99,
      rating: 4.5,
      imageUrl: 'https://example.com/fotoker.jpg',
      about: 'Cette crème contrôle les dommages actiniques et est dosée à 50ml.',
      description:
        'La crème dermatologique Fotoker apporte une double action préventive à la peau. Elle forme un film protecteur sur la peau et lui assure une protection solaire contre les UVA et UVB.',
    },
    {
      name: 'Crème antitaches protectrice spf50+ 50 ml',
      price: 24.99,
      rating: 4.7,
      imageUrl: 'https://example.com/creme-antitaches.jpg',
      about: 'Cette crème antitaches protège contre les rayons UV avec SPF50+ et est disponible en flacon de 50ml.',
      description:
        'La crème dermatologique Fotoker apporte une double action préventive à la peau. Elle forme un film protecteur sur la peau et lui assure une protection solaire contre les UVA et UVB.',
    },
    {
      name: 'Crème antitaches protectrice spf50+ 50 ml',
      price: 24.99,
      rating: 4.7,
      imageUrl: 'https://example.com/creme-antitaches.jpg',
      about: 'Cette crème antitaches protège contre les rayons UV avec SPF50+ et est disponible en flacon de 50ml.',
      description:
        'La crème dermatologique Fotoker apporte une double action préventive à la peau. Elle forme un film protecteur sur la peau et lui assure une protection solaire contre les UVA et UVB.',
    },
  ];
  productsDeConceil = [
    {
      name: 'Fotoker crème contrôle des dommages actiniques t doseur/50ml',
      price: 19.99,
      rating: 4.5,
      imageUrl: 'https://example.com/fotoker.jpg',
      about: 'Cette crème contrôle les dommages actiniques et est dosée à 50ml.',
      description:
        'La crème dermatologique Fotoker apporte une double action préventive à la peau. Elle forme un film protecteur sur la peau et lui assure une protection solaire contre les UVA et UVB.',
    },
    {
      name: 'Crème antitaches protectrice spf50+ 50 ml',
      price: 24.99,
      rating: 4.7,
      imageUrl: 'https://example.com/creme-antitaches.jpg',
      about: 'Cette crème antitaches protège contre les rayons UV avec SPF50+ et est disponible en flacon de 50ml.',
      description:
        'La crème dermatologique Fotoker apporte une double action préventive à la peau. Elle forme un film protecteur sur la peau et lui assure une protection solaire contre les UVA et UVB.',
    },
    {
      name: 'Crème antitaches protectrice spf50+ 50 ml',
      price: 24.99,
      rating: 4.7,
      imageUrl: 'https://example.com/creme-antitaches.jpg',
      about: 'Cette crème antitaches protège contre les rayons UV avec SPF50+ et est disponible en flacon de 50ml.',
      description:
        'La crème dermatologique Fotoker apporte une double action préventive à la peau. Elle forme un film protecteur sur la peau et lui assure une protection solaire contre les UVA et UVB.',
    },
  ];
  categoriesPara = [
    {
      name: 'Beauté & hygiène',
      image: '../../content/images/icons/visage.svg',
    },
    {
      name: 'Solaire',
      image: '../../content/images/icons/Solaire.svg',
    },
    {
      name: 'Nutrition, minceur & sport',
      image: '../../content/images/icons/Nutrition-minceur-sport.svg',
    },
    {
      name: 'Bébé & maman',
      image: '../../content/images/icons/Bébé & maman.svg',
    },
    {
      name: 'Homme',
      image: '../../content/images/icons/Homme.svg',
    },
    {
      name: 'Santé',
      image: '../../content/images/icons/Santé.svg',
    },
    {
      name: 'Sexualité',
      image: '../../content/images/icons/Sexualité.svg',
    },
    {
      name: 'Bio & naturel',
      image: '../../content/images/icons/Bio & naturel.svg',
    },
    {
      name: 'Vétérinaire',
      image: '../../content/images/icons/Vétérinaire.svg',
    },
    {
      name: 'Compléments alimentaires',
      image: '../../content/images/icons/Compléments alimentaires.svg',
    },
  ];
  productsPara = [
    {
      name: 'Fotoker crème contrôle des dommages actiniques t doseur/50ml',
      price: 19.99,
      rating: 4.5,
      imageUrl: 'https://example.com/fotoker.jpg',
      about: 'Cette crème contrôle les dommages actiniques et est dosée à 50ml.',
      description:
        'La crème dermatologique Fotoker apporte une double action préventive à la peau. Elle forme un film protecteur sur la peau et lui assure une protection solaire contre les UVA et UVB.',
    },
    {
      name: 'Crème antitaches protectrice spf50+ 50 ml',
      price: 24.99,
      rating: 4.7,
      imageUrl: 'https://example.com/creme-antitaches.jpg',
      about: 'Cette crème antitaches protège contre les rayons UV avec SPF50+ et est disponible en flacon de 50ml.',
      description:
        'La crème dermatologique Fotoker apporte une double action préventive à la peau. Elle forme un film protecteur sur la peau et lui assure une protection solaire contre les UVA et UVB.',
    },
    {
      name: 'Crème antitaches protectrice spf50+ 50 ml',
      price: 24.99,
      rating: 4.7,
      imageUrl: 'https://example.com/creme-antitaches.jpg',
      about: 'Cette crème antitaches protège contre les rayons UV avec SPF50+ et est disponible en flacon de 50ml.',
      description:
        'La crème dermatologique Fotoker apporte une double action préventive à la peau. Elle forme un film protecteur sur la peau et lui assure une protection solaire contre les UVA et UVB.',
    },
  ];
  categoriesDeConceil = [
    {
      name: 'Beauté & hygiène',
      image: '../../content/images/icons/visage.svg',
    },
    {
      name: 'Anxiété & sommeil',
      image: '../../content/images/icons/Anxiété-sommeil.svg',
    },
    {
      name: 'Nutrition, minceur & sport',
      image: '../../content/images/icons/Nutrition-minceur-sport.svg',
    },
    {
      name: 'Troubles métaboliques',
      image: '../../content/images/icons/Anxiété-sommeil.svg',
    },
    {
      name: 'Orl & troubles oculaires',
      image: '../../content/images/icons/Orl-troubles-oculaires.svg',
    },
    {
      name: 'Bucco dentaire',
      image: '../../content/images/icons/Bucco-dentaire.svg',
    },
    {
      name: 'Circulation & mémoire ',
      image: '../../content/images/icons/Circulation-mémoire.svg',
    },
    {
      name: 'Douleurs & fièvre',
      image: '../../content/images/icons/Douleurs-fièvre.svg',
    },
    {
      name: 'Fatigue & carences',
      image: '../../content/images/icons/Fatigue-carences.svg',
    },
    {
      name: 'Gynécologie ',
      image: '../../content/images/icons/Gynécologie.svg',
    },
    {
      name: 'Homéopathie',
      image: '../../content/images/icons/Homéopathie.svg',
    },
    {
      name: 'Sevrage tabagique',
      image: '../../content/images/icons/Sevrage-tabagique.svg',
    },
    {
      name: 'Troubles cutanés',
      image: '../../content/images/icons/Troubles-cutanés.svg',
    },
  ];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private pharmacieService: HomeServiceService,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private toastr: ToastrService,
  ) {
    this.PurchasesForm = this.createPurchasesForm();
  }

  ngOnInit(): void {
    this.pharmacyCartItemsName = this.cartService.getPharmacyName();
    const selectedAddressId = localStorage.getItem('selectedAddressId');
    if (selectedAddressId) {
      this.selectedAddressId = parseInt(selectedAddressId, 10);
    }
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 1000);
    this.getPharmacyId();
    this.pharmacyName = this.pharmacieService.getCommercialName();
    this.getParmacyPromos();
  }
  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }
  getPharmacyId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.pharmacyId = +params.get('pharmacieId');
        this.pharmacyType = params.get('T');

        console.log('pharmacieType', this.pharmacyType);
        this.getCategories(this.pharmacyType);
      },
    });
  }
  changeCategoryP() {
    this.activeCategory = 'P';
    this.router.navigate(['/pharmacie-category', { pharmacieId: this.pharmacyId, T: 'P' }]);
  }
  changeCategoryM() {
    this.activeCategory = 'M';
    this.router.navigate(['/pharmacie-category', { pharmacieId: this.pharmacyId, T: 'M' }]);
  }
  getPharmacyName() {
    this.route.queryParams.subscribe(params => {
      this.pharmacyName = params['pharmacyName'] || '';
    });
  }
  navigateToSubCategory(category: any) {
    this.router.navigate(['/pharmacie-sub-category', { pharmacieId: this.pharmacyId, categoryId: category.id }]);
    this.setCatName(category.name);
    this.pharmacieService.setSelectedCategory(category);
  }
  setCatName(catName: any) {
    this.pharmacieService.setCatName(catName);
  }
  openCommandModal(modal: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }
  ordonnanceImages: Array<string | ArrayBuffer> = [];
  prescriptionFiles: File[] = [];
  vitalCardFiles: File[] = [];
  mutualCardFiles: File[] = [];

  onFileSelected(event: Event, fileType: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.PurchasesForm.get(fileType)?.setValue(file);
      const files = Array.from(inputElement.files);

      if (fileType === 'prescription') {
        this.prescriptionFiles = [...this.prescriptionFiles, ...files];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.ordonnanceImages.push(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        const spanElement = inputElement.nextElementSibling as HTMLElement;
        if (spanElement) {
          spanElement.textContent = file.name;
        }
      }
      files.forEach(file => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (fileType === 'prescription') {
            this.ordonnanceImages.push(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  }
  getCategories(pharmacyType: any): void {
    if (this.currentPage) {
      this.pharmacieService.getCategories(pharmacyType, this.pharmacyId, 0).subscribe({
        next: (res: any) => {
          this.categories = res.filter((category: any) =>
            category.subCategories.some((subCategory: any) => subCategory.stockItemsAccount !== 0),
          );
          console.log('filtered categories', this.categories);
        },
        error: (err: any) => {
          console.error('Error fetching pharmacies:', err);
        },
      });
    } else {
      console.error('Current page is not defined');
    }
  }
  getParmacyPromos(): void {
    if (this.currentPage) {
      this.pharmacieService.getPromos(this.pharmacyId).subscribe({
        next: (res: any) => {
          this.promos = res;
        },
        error: (err: any) => {
          console.error('Error fetching pharmacies:', err);
        },
      });
    } else {
      console.error('Current page is not defined');
    }
  }
  createPurchasesForm(data?: any): FormGroup {
    return this.formBuilder.group({
      firstName: [data ? data.firstName : ''],
      lastName: [data ? data.lastName : ''],
      prescription: [data ? data.prescription : null],
      mutual_card: [data ? data.mutual_card : null],
      vital_card: [data ? data.vital_card : null],
    });
  }
  consentGiven = false;
  onCheckboxChange(event: any): void {
    this.consentGiven = event.target.checked; // Mettez à jour l'état de la case à cocher
  }
  removeFile(fileToRemove: File): void {
    this.prescriptionFiles = this.prescriptionFiles.filter(file => file !== fileToRemove); // Supprimer le fichier
  }
  addPurchases(purchaseId: any): void {
    this.PurchasesForm.markAllAsTouched();
    this.PurchasesForm.markAsDirty();
    const formData = new FormData();

    const formControls = this.PurchasesForm.controls;
    this.prescriptionFiles.forEach(file => {
      formData.append('prescription', file);
    });

    // Ajouter les autres fichiers
    ['vital_card', 'mutual_card'].forEach(fileType => {
      const file = formControls[fileType]?.value;
      if (file) {
        formData.append(fileType, file);
      }
    });
    this.uploadFiles(formData, purchaseId);
  }

  uploadFiles(formData: FormData, purchaseId: any): void {
    this.pharmacieService.uploadFiles(purchaseId, formData).subscribe({
      next: (res: any) => {
        console.log('Response for file upload:', res);
        this.toastr.success('Files uploaded successfully');
        this.getAllFilesUrl();
        this.resetForm();
        this.cartService.addPrescriptionToCart();
      },
      error: (err: any) => {
        console.log('Error uploading files', err);
        this.toastr.error('Error uploading files');
      },
    });
  }

  resetForm() {
    this.prescriptionFiles = [];
    this.fileUrls = [];
    this.vitalCardFiles = [];
    this.mutualCardFiles = [];
    this.PurchasesForm?.reset();
  }

  documents: any;
  prescriptionUrls: string[] = [];
  vitalCardUrl: string | null = null;
  mutualCardUrl: string | null = null;
  getAllFilesUrl(): void {
    const purchaseId = this.cartService.getPurchaseId();
    this.pharmacieService.getFileUrl(purchaseId).subscribe(res => {
      this.documents = res;
      this.prescriptionUrls = res.prescriptionFiles || [];
      this.vitalCardUrl = res.vital_card || null;
      this.mutualCardUrl = res.mutual_card || null;
      console.log(`Files URL: ${res}`);
    });
  }
  getFileUrl(file: File): string {
    return URL.createObjectURL(file);
  }
  cookiesCart!: any[];
  total: any;
  addOrder() {
    let purchaseStockItems: any = [];
    this.cookiesCart?.forEach(async item => {
      purchaseStockItems.push({
        stockItem: { id: item.id },
        requestedQuantity: item.quantity,
      });
    });
    const cartData = {
      pharmacyId: this.pharmacyId,
      deliveryAddressId: this.selectedAddressId,
      purchaseStockItems: purchaseStockItems,
    };
    this.pharmacieService.addToCart(cartData).subscribe(
      response => {
        this.addPurchases(response.id);
        this.cartService.setPurchaseId(response.id);
        this.cartService.setNumCommande(response.num);
      },
      error => {
        console.error('Error adding product to cart:', error);
      },
    );
  }

  onFileSelectedPrescription(event: any): void {
    const files: FileList = event.target.files;

    // Check if number of files exceeds the limit
    if (this.prescriptionFiles.length + files.length > this.maxFiles) {
      this.toastr.info(`You can only upload a maximum of ${this.maxFiles} files.`, 'File Limit Exceeded');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Validate file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.toastr.info('Only images (JPG, PNG) or PDFs are allowed.', 'Invalid File Type');
        continue;
      }

      // Validate file size
      if (file.size > this.maxFileSize) {
        this.toastr.info(`File size should not exceed ${this.maxFileSize / (1024 * 1024)} MB.`, 'File Size Exceeded');
        continue;
      }

      // Add file to list if valid
      this.prescriptionFiles.push(file);

      // Use FileReader to generate preview URL
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileUrls.push(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  removeFilePrescription(file: File): void {
    const index = this.prescriptionFiles.indexOf(file);
    if (index > -1) {
      this.prescriptionFiles.splice(index, 1);
      this.fileUrls.splice(index, 1);
    }
  }

  increment() {
    this.value++;
  }
  decrement() {
    if (this.value > 1) {
      this.value--;
    }
  }
  openproductModal(modal: any, product: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
    this.openedProduct = product;
    this.value = this.cartService.getCurrent(product);
  }
  openModal(modalTemplate: TemplateRef<any>, product: any) {
    this.modalService.open(modalTemplate, { size: 's', centered: true }).result.then(
      result => {
        if (result === 'clearCart') {
          this.cartService.clearCartItems();
          if (this.openedProduct) {
            this.addToCartWithASpecificNumber();
          } else {
            this.addToCart(product, this.modalProductExist);
          }
        } else {
          console.log('User canceled the action.');
        }
      },
      reason => {
        console.log('Modal dismissed:', reason);
      },
    );
  }
  addToCartWithASpecificNumber() {
    if (!this.cartService.checkSamePharmacy(this.openedProduct)) {
      this.openModal(this.modalProductExist, this.openedProduct);
    } else {
      //this.cartService.setPurchaseId(response.id);
      //   console.log('Product added to cart:', response);
      this.cartService.addToCartWithASpecificNumber(this.openedProduct, this.value);
      this.toastr.success('Product added to cart');
    }
  }
  addToCart(product: any, modalTemplate: TemplateRef<any>): void {
    if (!this.cartService.checkSamePharmacy(product)) {
      this.openModal(modalTemplate, product);
    } else {
      this.cartService.addToCart(product);
      this.toastr.success('Product added to cart');
    }
  }
  toggleExpandDescription() {
    this.isExpandedDescription = !this.isExpandedDescription;
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
  toggleLike(product: any, index: number) {
    if (product.liked) {
      this.unlikeProduct(product.id, index);
    } else {
      this.likeProduct(product.id, index);
    }
  }
  likeProduct(product_id: any, index: number) {
    let data = {
      favorable_type: 'StockItem',
      favorable_id: product_id,
    };
    this.pharmacieService.likeProduct(data).subscribe(
      response => {
        console.log('API Response:', response);
        this.promos[index].liked = true;
      },
      error => {
        console.error('Error liking pharmacy:', error);
      },
    );
  }
  unlikeProduct(product_id: any, index: number) {
    let data = {
      favorable_type: 'StockItem',
      favorable_id: product_id,
    };
    this.pharmacieService.unlikeProduct(data).subscribe(
      response => {
        console.log('API Response:', response);
        this.promos[index].liked = false;
      },
      error => {
        console.error('Error unliking pharmacy:', error);
      },
    );
  }
}
