<div class="row mt-5">
  <h2 class="lead" style="color: #141414">Liste des commandes</h2>
</div>
<div class="row">
  <div class="col-8">
    <ul ngbNav [(activeId)]="activeTab" #nav="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink (click)="changeStatus('CREATED_WITH_PRESCRIPTION'); changeTab(1)" [class.active]="activeTab === 1"
          >Nouvelle
          <span class="badge" *ngIf="newPurchasesCount['CREATED_WITH_PRESCRIPTION'] > 0">
            +{{ newPurchasesCount['CREATED_WITH_PRESCRIPTION'] }}
          </span></a
        >
        <ng-template ngbNavContent>
          <div [ngClass]="createdWithPrescriptionPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Num Commande</th>
                    <th scope="col">Date de création</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let purshase of purshases; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ purshase?.id }}</td>
                      <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                      <td class="adresse">
                        {{ purshase?.deliveryAddress?.streetNumber }} {{ purshase?.deliveryAddress?.streetName }}
                        {{ purshase?.deliveryAddress?.city }} {{ purshase?.deliveryAddress?.state }}
                        {{ purshase?.deliveryAddress?.postalCode }} {{ purshase?.deliveryAddress?.country }}
                      </td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                          <span
                            *ngIf="
                              purshase?.vitalCardFilename.length ||
                              purshase?.prescriptionFilename.length ||
                              purshase?.mutualCardFilename.length
                            "
                            >ORDO</span
                          >
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button
                            class="btn-payer"
                            (click)="acceptCommande(purshase?.id)"
                            *ngIf="purshase.lastStatus.name == 'CREATED_WITH_PRESCRIPTION'"
                          >
                            Accepter
                          </button>
                          <button class="btn-command btn-delete" (click)="onDeleteClick(purshase?.id)"></button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--
          -->
            <div class="card flex justify-content-center" *ngIf="createdWithPrescriptionPurchasesCount">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
            <ng-container *ngIf="!createdWithPrescriptionPurchasesCount">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink (click)="changeStatus('IN_PROGRESS'); changeTab(2)" [class.active]="activeTab === 2"
          >En cours
          <span class="badge" *ngIf="newPurchasesCount['IN_PROGRESS'] > 0"> +{{ newPurchasesCount['IN_PROGRESS'] }} </span>
        </a>
        <ng-template ngbNavContent>
          <div [ngClass]="inProgressPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Num Commande</th>
                    <th scope="col">Date de création</th>
                    <th scope="col">Traitement</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let purshase of purshases; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ purshase?.id }}</td>
                      <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                      <td>{{ purshase?.lastStatus?.date | date: "HH'h'mm" }}</td>
                      <td>{{ purshase?.totalAmount.toFixed(2) }}€</td>
                      <td class="adresse">
                        {{ purshase?.deliveryAddress?.streetNumber }} {{ purshase?.deliveryAddress?.streetName }}
                        {{ purshase?.deliveryAddress?.city }} {{ purshase?.deliveryAddress?.state }}
                        {{ purshase?.deliveryAddress?.postalCode }}
                        {{ purshase?.deliveryAddress?.country }}
                      </td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                          <span
                            *ngIf="
                              purshase?.vitalCardFilename.length ||
                              purshase?.prescriptionFilename.length ||
                              purshase?.mutualCardFilename.length
                            "
                            >ORDO</span
                          >
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer" (click)="confirmedCommande(purshase?.id)">Prête</button>
                          <button class="btn-command btn-delete" (click)="onDeleteClick(purshase?.id)"></button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" style="border: none" *ngIf="inProgressPurchasesCount">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"></p-paginator>
            </div>
            <ng-container *ngIf="!inProgressPurchasesCount">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink (click)="changeStatus('PAYMENT_INSTANCE', 'READY'); changeTab(3)" [class.active]="activeTab === 3"
          >Prête
          <span class="badge" *ngIf="newPurchasesCount['PAYMENT_INSTANCE'] > 0"> +{{ newPurchasesCount['PAYMENT_INSTANCE'] }} </span>
        </a>
        <ng-template ngbNavContent>
          <div [ngClass]="readyPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Num Commande</th>
                    <th scope="col">Date de création</th>
                    <th scope="col">Traitement</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let purshase of purshases; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ purshase?.id }}</td>
                      <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                      <td>{{ purshase?.lastStatus?.date | date: "HH'h'mm" }}</td>

                      <td>{{ purshase?.totalAmount.toFixed(2) }}€</td>
                      <td class="adresse">
                        {{ purshase?.deliveryAddress?.streetNumber }} {{ purshase?.deliveryAddress?.streetName }}
                        {{ purshase?.deliveryAddress?.city }}
                        {{ purshase?.deliveryAddress?.postalCode }}
                        {{ purshase?.deliveryAddress?.country }}
                      </td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                          <span
                            *ngIf="
                              purshase?.vitalCardFilename.length ||
                              purshase?.prescriptionFilename.length ||
                              purshase?.mutualCardFilename.length
                            "
                            >ORDO</span
                          >
                        </div>
                      </td>
                      <td *ngIf="purshase.payment != null">Payé</td>
                      <td *ngIf="purshase.payment == null">En Attente</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer" [disabled]="purshase.payment == null">Emballer</button>
                          <!--<button class="btn-command btn-delete" (click)="onDeleteClick(purshase?.id)"></button>-->
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" style="border: none" *ngIf="readyPurchasesCount">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"></p-paginator>
            </div>
            <ng-container *ngIf="!readyPurchasesCount">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink (click)="changeStatus('PACKAGING'); changeTab(4)">Emballage</a>
        <ng-template ngbNavContent>
          <div [ngClass]="packagingPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Num Commande</th>
                    <th scope="col">Date de création</th>
                    <th scope="col">Traitement</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Livraison</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let purshase of purshases; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ purshase?.id }}</td>
                      <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                      <td>{{ purshase?.lastStatus?.date | date: "HH'h'mm" }}</td>

                      <td class="adresse">
                        {{ purshase?.deliveryAddress?.streetNumber }} {{ purshase?.deliveryAddress?.streetName }}
                        {{ purshase?.deliveryAddress?.city }} {{ purshase?.deliveryAddress?.state }}
                        {{ purshase?.deliveryAddress?.postalCode }}
                        {{ purshase?.deliveryAddress?.country }}
                      </td>
                      <td *ngIf="purshase?.clickAndCollect === true">Click and collect</td>
                      <td *ngIf="purshase?.clickAndCollect === false">Livraison à domicile</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                          <span
                            *ngIf="
                              purshase?.vitalCardFilename.length ||
                              purshase?.prescriptionFilename.length ||
                              purshase?.mutualCardFilename.length
                            "
                            >ORDO
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer" (click)="livrerCommande(purshase?.id)">Livrer</button>
                          <!--
                          <button class="btn-command btn-localisation"></button>
                          <button class="btn-command btn-download"></button>
                        --></div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" style="border: none" *ngIf="packagingPurchasesCount">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"></p-paginator>
            </div>
            <ng-container *ngIf="!packagingPurchasesCount">
              <lottie-player
                src="../../content/images/noData.json"
                background="transparent"
                speed="1"
                style="width: 300px; height: 250px; margin: auto"
                loop
                autoplay
              ></lottie-player>
            </ng-container>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink (click)="changeStatus('READY', 'RECOVERING', 'DELIVERING', 'DELIVERED', 'RECOVERED'); changeTab(5)"
          >En Livraison <span class="badge" *ngIf="newPurchasesCount['READY'] > 0"> +{{ newPurchasesCount['READY'] }} </span>
        </a>
        <ng-template ngbNavContent>
          <div [ngClass]="purshases.length > 7 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Num Commande</th>
                    <th scope="col">Date de création</th>
                    <th scope="col">Étape</th>
                    <th scope="col">Livraison</th>
                    <th scope="col">Code livreur</th>
                    <th scope="col">Code Client</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let purshase of purshases; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ purshase?.id }}</td>
                      <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                      <td>
                        <button
                          class="attenteLivreur"
                          *ngIf="purshase?.delivery?.status === 'CREATED' && purshase?.clickAndCollect === false"
                        >
                          Attente livreur
                        </button>
                        <button class="enRoute" *ngIf="purshase?.delivery?.status === 'IN_TRANSIT'">En route</button>
                        <button class="clientAbsent" *ngIf="purshase?.delivery?.status === 'RETURNED'">Client Absent</button>
                        <button class="attenteLivreur" *ngIf="purshase?.delivery?.status === 'DELIVERED'">Livrée</button>

                        <span *ngIf="purshase?.clickAndCollect === true && purshase?.delivery?.status !== 'DELIVERED'">---</span>
                      </td>
                      <td *ngIf="purshase?.clickAndCollect === true">Click and collect</td>
                      <td *ngIf="purshase?.clickAndCollect === false">Livraison à domicile</td>
                      <td>
                        <span *ngIf="purshase?.delivery?.status !== 'CREATED' || purshase?.clickAndCollect === true" style="color: red"
                          >Ø</span
                        >
                        <div class="btn-wrap" *ngIf="purshase?.delivery?.status === 'CREATED' && purshase?.clickAndCollect === false">
                          <button class="btn-command btn-localisation" (click)="openModalOtpDelivery(purshase?.id)"></button>
                          <!--<button class="btn-command btn-download"></button>-->
                        </div>
                      </td>
                      <td>
                        <div
                          class="btn-wrap"
                          *ngIf="
                            (purshase?.delivery?.status === 'RETURNED' || purshase?.clickAndCollect === true) &&
                              purshase?.delivery?.status !== 'DELIVERED';
                            else noButton
                          "
                        >
                          <button class="btn-command btn-localisation" (click)="openModalOtpClientDelivery(purshase?.id)"></button>
                        </div>
                        <ng-template #noButton>
                          <span style="color: red">Ø</span>
                        </ng-template>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                        </div>
                      </td>
                      <td>
                        <div class="date-header" ngbDropdown>
                          <span style="cursor: pointer" ngbDropdownToggle>
                            <p style="font-family: emoji; font-size: 40px; margin-top: -5px">...</p>
                          </span>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button
                              ngbDropdownItem
                              (click)="openModalColisConforme(purshase?.id)"
                              [disabled]="purshase?.delivery?.status !== 'IN_TRANSIT'"
                            >
                              Valider le retour colis
                            </button>
                            <button ngbDropdownItem [disabled]="purshase?.delivery?.status !== 'RETURNED'">Livrer</button>
                            <button ngbDropdownItem>Autre action</button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card flex justify-content-center" style="border: none" *ngIf="purshases?.length">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"></p-paginator>
          </div>
          <ng-container *ngIf="!purshases?.length">
            <lottie-player
              src="../../content/images/noData.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 250px; margin: auto"
              loop
              autoplay
            ></lottie-player>
          </ng-container>
        </ng-template>
      </li>
      <li [ngbNavItem]="6">
        <a ngbNavLink (click)="changeStatus('CANCELED', 'REJECTED', 'LOST'); changeTab(6)">Archivées</a>
        <ng-template ngbNavContent>
          <div class="table-container">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Num Commande</th>
                  <th scope="col">Date de création</th>
                  <th scope="col">Statut</th>
                  <th scope="col">Détails</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0714528/24</td>
                  <td>24/04/2024 10h45</td>
                  <td>
                    <div class="btn-wrap">
                      <span class="badge bg-primary">Livrée</span>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-view"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>0714528/24</td>
                  <td>24/04/2024 10h45</td>
                  <td>
                    <div class="btn-wrap">
                      <span class="badge bg-danger">Annulée</span>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-view"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>0714528/24</td>
                  <td>24/04/2024 10h45</td>
                  <td>
                    <div class="btn-wrap">
                      <span class="badge bg-success">Indisponible</span>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-view"></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </li>
      <!--
      -->
    </ul>
  </div>
  <div class="col-4">
    <div class="product-search m-2">
      <input
        type="text"
        class="form-control category-input"
        id="text"
        name="search"
        (keyup)="applyFilter($event)"
        placeholder="Recherche ..."
        data-cy="text"
      />
      <span class="search-icon"></span>
    </div>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<ng-template #modalOtpDelivery let-modalOtpDelivery>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalOtpDelivery.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <p class="swalModal">Vérification de code</p>
      <p class="swalModalCommande">Commande ID: xxxxxx</p>
      <!--[formCtrl]="otpForm"-->
      <span class="mb-4 productExist"> Veuillez saisir le code de vérification (6 chiffres) fourni par le livreur</span>
      <ng-otp-input #otp (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>

      <div class="d-flex w-100" style="flex-wrap: wrap; justify-content: center">
        <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="verifyPurchasesOtp(); modalOtpDelivery.dismiss('Cross click')">
          Soumettre
        </button>
        <p class="annuler" (click)="modalOtpDelivery.dismiss('Cross click')">Annuler</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #modalOtpClientDelivery let-modalOtpClientDelivery>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalOtpClientDelivery.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <p class="swalModal">Vérification de code</p>
      <p class="swalModalCommande">Commande ID: xxxxxx</p>
      <!--[formCtrl]="otpForm"-->
      <span class="mb-4 productExist"> Veuillez saisir le code de vérification (6 chiffres) fourni par le livreur</span>
      <ng-otp-input #otp (onInputChange)="onOtpClientChange($event)" [config]="{ length: 6 }"></ng-otp-input>

      <div class="d-flex w-100" style="flex-wrap: wrap; justify-content: center">
        <button
          class="btn btn-success mt-3 mb-3 saveAdresse"
          (click)="verifyPurchasesOtpClient(); modalOtpClientDelivery.dismiss('Cross click')"
        >
          Soumettre
        </button>
        <p class="annuler" (click)="modalOtpClientDelivery.dismiss('Cross click')">Annuler</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #modalOtpValide let-modalOtpValide>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalOtpValide.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/Icon feather-check-circle.svg" alt="eather-check-circle" width="24%" />
      <p class="swalModalValid">Le code est validé avec succès.</p>
      <span class="mb-4 productExist"> Cette opération est enregistrée. Vous pouvez livrer le colis au livreur</span>
      <div class="d-flex justify-content-between w-100">
        <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="modalOtpValide.close('closeCart')">J’ai compris</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #modalOtpFailed let-modalOtpFailed>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalOtpFailed.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/problem.svg" alt="" />
      <p class="swalModalFailed">Le code n’est pas validé.</p>
      <span class="mb-4 productExist"> Cette opération est enregistrée.</span>
      <div class="d-flex justify-content-between w-100">
        <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="modalOtpFailed.close('closeCart')">J’ai compris</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #modalColisConforme let-modalColisConforme>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalColisConforme.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/problem.svg" alt="" />
      <p class="swalModalFailed">Rappel !</p>
      <span class="mb-4 productExist"
        >Veuillez procéder à la vérification du contenu du colis et s’assurer de la conformité de la commande d’origine.
      </span>
      <div class="d-flex w-100" style="flex-wrap: wrap">
        <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="returned(); modalColisConforme.dismiss('Cross click')">
          Colis conforme
        </button>
        <button class="btn btn-success mt-3 mb-3 noConforme" (click)="openModalAnomalie()">Signaler une anomalie</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #modalAnomalie let-modalAnomalie>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalAnomalie.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/problem.svg" alt="" />
      <p class="swalModalFailed">Veuillez sélectionner l’anomalie.</p>
      <span class="mb-4 productExist"> Liste de motifs.</span>
      <select class="form-control">
        <option>Liste de motifs.</option>
        <option>motifs 1.</option>
        <option>motifs 2.</option>
        <option>motifs 3.</option>
      </select>
      <div class="d-flex justify-content-between w-100">
        <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="modalAnomalie.close('closeCart')">Valider</button>
      </div>
    </div>
  </div>
</ng-template>
