import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Account } from '../core/auth/account.model';
import { AccountService } from '../core/auth/account.service';
import { LoginService } from '../login/login.service';
import SharedModule from '../shared/shared.module';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'jhi-land',
  standalone: true,
  templateUrl: './land.component.html',
  styleUrls: ['./land.component.scss'],
  imports: [SharedModule, FormsModule, ReactiveFormsModule, RouterModule],
})
export class LandComponent implements OnInit, AfterViewInit {
  @ViewChild('username', { static: false })
  username!: ElementRef;

  authenticationError = false;

  loginForm = new FormGroup({
    username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    rememberMe: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
  });
  params: any;
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  account: Account | null = null;
  private readonly destroy$ = new Subject<void>();
  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getScreenSize(event?: Event) {
    console.log(event);

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private _toaster: ToastrService,
  ) {}

  ngOnInit(): void {
    localStorage.setItem('isLandComponentActive', 'true');
    // if already authenticated then navigate to home page
    this.accountService.identity().subscribe(() => {
      if (this.accountService.isAuthenticated()) {
        this.accountService
          .getAuthenticationState()
          .pipe(takeUntil(this.destroy$))
          .subscribe(account => (this.account = account));
        console.log(this.account);
        if (
          (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) &&
          this.account?.authorities.includes('ROLE_PHARMACIST')
        ) {
          // eslint-disable-next-line curly
          // this.router.navigate(['/login'], { queryParams: { user: 'completeCreation' } });
          // eslint-disable-next-line curly, no-empty
        } else {
          this.router.navigate(['/home']);
        }
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params['type']) {
        this.params = params['type'];
      }
    });
  }

  ngAfterViewInit(): void {
    this.username.nativeElement.focus();
    window.scrollTo(0, 0);
  }
  passwordComplexityValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /\d/.test(value);
      const passwordValid = hasUpperCase && hasNumber;
      return !passwordValid ? { passwordComplexity: true } : null;
    };
  }
  login(): void {
    this.loginService.login(this.loginForm.getRawValue()).subscribe({
      next: () => {
        this.authenticationError = false;
        if (!this.router.getCurrentNavigation()) {
          // There were no routing during login (eg from navigationToStoredUrl)
          this.accountService
            .getAuthenticationState()
            .pipe(takeUntil(this.destroy$))
            .subscribe(account => (this.account = account));
          console.log(this.account);
          if (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted)
            // eslint-disable-next-line curly
            this.router.navigate(['/login'], { queryParams: { user: 'completeCreation' } });
          // eslint-disable-next-line curly
          else this.router.navigate(['/home']);
        }
      },
      error: () => {
        this.authenticationError = true;
        this._toaster.error(this.translateService.instant('login.messages.error.authentication'));
      },
    });
    // this.toastr.success('fdfdfdfdf');
  }
  showPassword = false;

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy(): void {
    localStorage.setItem('isLandComponentActive', 'false');
    this.destroy$.next();
    this.destroy$.complete();
  }
}
